import React, { useState, useEffect } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import {
  IonImg,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonButton,
  IonText,
  IonRouterOutlet,
  IonContent,
  IonApp,
} from '@ionic/react';
import { Plugins } from '@capacitor/core';
import Home from '../Home';
import Faturamento from '../Faturamento';
import Financeiro from '../Financeiro';
import Estoque from '../Estoque';
import { TitleApp } from '../../../styles/shared';
import Servicos from '../Servicos';
const { Storage } = Plugins;
const menuIcons = {
  inicio: require('../../../assets/svg/menu/icon-inicio.svg'),
  faturamento: require('../../../assets/svg/menu/icon-faturamento.svg'),
  financeiro: require('../../../assets/svg/menu/icon-financeiro.svg'),
  estoque: require('../../../assets/svg/menu/icon-estoque.svg'),
  servico: require('../../../assets/svg/menu/icon-servicos.svg'),
  configuracao: require('../../../assets/svg/menu/icon-configuracao.svg'),
};

const Tabs = ({ handleCloseMenu }) => {
  const [empresa, setEmpresa] = useState({});
  const [isService, setIsService] = useState(false);

  useEffect(() => {
    Storage.get({ key: 'empresaLicenciada' }).then(data => {
      setEmpresa(JSON.parse(data.value));
    });
    Storage.get({ key: 'isService' }).then(data => {
      setIsService(data.value);
    });
  }, []);

  async function handleLogout() {
    await Storage.remove({ key: 'usuarioLogado' });
    await Storage.remove({ key: 'usuarioToken' });
    window.location.href = '/login';
  }

  return (
    <IonApp>
      <IonHeader>
        <IonToolbar
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 8,
          }}
          color="primary">
          <TitleApp>
            SIAF <span>Controller</span>
          </TitleApp>
          <IonButtons slot="primary">
            <IonButton onClick={() => handleLogout()}>
              <IonIcon
                slot="icon-only"
                icon={require('ionicons/icons')['logOut']}
                size="large"
              />
            </IonButton>
          </IonButtons>
          <div style={{ marginTop: 6 }}>
            <IonText
              style={{
                marginRight: 6,
                fontSize: 12,
              }}>{`EMPRESA - ${empresa?.razaoSocial}`}</IonText>
          </div>
          <div>
            <IonText
              style={{
                marginTop: 8,
                fontSize: 12,
              }}>
              {empresa?.cnpj?.length > 14
                ? `CNPJ - ${empresa?.cnpj}`
                : `CPF - ${empresa?.cnpj}`}
            </IonText>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/tabs/dashboard" component={Home} exact />
            <Route path="/tabs/faturamento" component={Faturamento} exact />
            <Route path="/tabs/financeiro" component={Financeiro} exact />
            <Route path="/tabs/estoque" component={Estoque} exact />
            <Route path="/tabs/servicos" component={Servicos} exact />
            <Route exact path="/tabs" render={() => <Redirect to="/tabs/dashboard" />} />
          </IonRouterOutlet>

          <IonTabBar mode="ios" slot="bottom">
            <IonTabButton tab="dashboard" href="/tabs/dashboard">
              <IonImg src={menuIcons['inicio']} style={{ width: 24 }} alt="Início" />
              <IonLabel>Início</IonLabel>
            </IonTabButton>
            <IonTabButton tab="faturamento" href="/tabs/faturamento">
              <IonImg
                src={menuIcons['faturamento']}
                style={{ width: 24 }}
                alt="Faturamento"
              />
              <IonLabel>Faturamento</IonLabel>
            </IonTabButton>
            <IonTabButton tab="financeiro" href="/tabs/financeiro">
              <IonImg
                src={menuIcons['financeiro']}
                style={{ width: 24 }}
                alt="Financeiro"
              />
              <IonLabel>Financeiro</IonLabel>
            </IonTabButton>
            <IonTabButton tab="estoque" href="/tabs/estoque">
              <IonImg src={menuIcons['estoque']} style={{ width: 24 }} alt="Estoque" />
              <IonLabel>Estoque</IonLabel>
            </IonTabButton>
            {isService === 'true' && (
              <IonTabButton tab="servicos" href="/tabs/servicos">
                <IonImg src={menuIcons['servico']} style={{ width: 24 }} alt="Serviços" />
                <IonLabel>Serviços</IonLabel>
              </IonTabButton>
            )}
          </IonTabBar>
        </IonTabs>
      </IonContent>
    </IonApp>
  );
};

export default withRouter(Tabs);
