import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { BehaviorSubject } from 'rxjs';
import api, { onRefreshing } from '../../../../services/api';
import { formatCurrency, formatDateApi } from '../../../../utils/formats';
import Card from '../../Card';
import PieChart from '../../../Charts/PieChart';

const onSelect = new BehaviorSubject();

class CardRecebimentoPorTipoPagamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      period: '',
      data: null,
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());
      onSelect.next({ dataInicial, dataFinal });
    });
    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({
          refreshing: true,
          data: {
            series: [],
          },
        });
        this.asyncRequest = api
          .get('/financeiro/recebimento-tipopagamento/', {
            headers,
          })
          .then(({ data }) => {
            this.asyncRequest = null;
            const listaPagamentos = data.length > 5 ? data.slice(0, 5) : data;
            const listaOutrosPagamentos =
              data.length > 5 ? data.slice(5, data.length) : [];
            const pagamentos = listaPagamentos
              .filter(({ VALORTOTAL }) => VALORTOTAL > 0)
              .map(({ TIPOPAGAMENTOID, TIPOPAGAMENTO, VALORTOTAL }) => ({
                name: `${TIPOPAGAMENTOID} - ${TIPOPAGAMENTO}`,
                y: Number(VALORTOTAL),
              }));
            if (listaOutrosPagamentos.length > 0) {
              pagamentos.push({
                name: 'OUTROS',
                y: listaOutrosPagamentos.reduce(
                  (total, { VALORTOTAL }) => total + Number(VALORTOTAL),
                  0,
                ),
              });
            }
            this.setState({
              refreshing: false,
              data:
                data.length > 0
                  ? {
                      series: [
                        {
                          name: 'pagamentos',
                          colorByPoint: true,
                          data: [...pagamentos],
                        },
                      ],
                    }
                  : {
                      series: [],
                    },
            });
          });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.periodo !== prevProps.periodo) {
      const headers = {};
      headers.dataInicial = this.props.dateRange.dataInicial;
      headers.dataFinal = this.props.dateRange.dataFinal;
      this.setState({ period: this.props.periodo });
      if (headers.dataInicial && headers.dataFinal) {
        this.setState({
          refreshing: true,
          data: {
            series: [],
          },
        });
        this.asyncRequest = api
          .get('/financeiro/recebimento-tipopagamento/', {
            headers,
          })
          .then(({ data }) => {
            this.asyncRequest = null;
            const listaPagamentos = data.length > 5 ? data.slice(0, 5) : data;
            const listaOutrosPagamentos =
              data.length > 5 ? data.slice(5, data.length) : [];
            const pagamentos = listaPagamentos
              .filter(({ VALORTOTAL }) => VALORTOTAL > 0)
              .map(({ TIPOPAGAMENTOID, TIPOPAGAMENTO, VALORTOTAL }) => ({
                name: `${TIPOPAGAMENTOID} - ${TIPOPAGAMENTO}`,
                y: Number(VALORTOTAL),
              }));
            if (listaOutrosPagamentos.length > 0) {
              pagamentos.push({
                name: 'OUTROS',
                y: listaOutrosPagamentos.reduce(
                  (total, { VALORTOTAL }) => total + Number(VALORTOTAL),
                  0,
                ),
              });
            }
            this.setState({
              refreshing: false,
              data:
                data.length > 0
                  ? {
                      series: [
                        {
                          name: 'pagamentos',
                          colorByPoint: true,
                          data: [...pagamentos],
                        },
                      ],
                    }
                  : {
                      series: [],
                    },
            });
          });
      }
    }
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
    if (this.asyncRequest) this.asyncRequest.unsubscribe();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { period, data, refreshing } = this.state;
    return (
      <>
        <Card
          className="card card__primary"
          title="Rec. por Tipo de pagamento"
          period={period}
          onChangeDate={this.onChangeDate}>
          <PieChart
            refreshing={refreshing}
            data={data}
            options={{
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    style: {
                      color: '#fff',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              },
            }}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardRecebimentoPorTipoPagamento);
