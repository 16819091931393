import React from 'react';
import { IonImg } from '@ionic/react';

const icons = {
  box: require('../../assets/svg/icon-box.svg'),
  pieChart: require('../../assets/svg/icon-pie-chart.svg'),
  lineChart: require('../../assets/svg/icon-line-chart.svg'),
  barChart: require('../../assets/svg/icon-bar-chart.svg'),
};

export default function Empty({ icon, width, cardStyle }) {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        padding: 30,
      }}>
      <IonImg src={icons[icon]} style={{ width: width ? width : 128 }} alt="Vazio" />
      <p
        style={{
          color: `${cardStyle === 'card card__light' ? '#119FE3' : '#FFF'}`,
          fontSize: 13,
          fontWeight: 400,
          textAlign: 'center',
          marginTop: 20,
        }}>
        Nenhum dado encontrado <br /> com o período informado.
      </p>
    </div>
  );
}
