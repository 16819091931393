import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import { formatCurrency } from '../../../utils/formats';
import Loading from '../../Loading';

require('highcharts/modules/full-screen')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

export default function CustomChart({ refreshing, numberFormat = 'currency', data, options }) {
  const chartOptions = {};

  _.merge(
    chartOptions,
    {
      title: {
        text: '',
        style: {
          color: '#0C0C0C',
        },
      },
      chart: {
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        plotBorderColor: null,
        height: 300,
        style: {
          fontFamily: "'Unica One', sans-serif",
        },
      },
      responsive: true,
      credits: {
        enabled: false,
      },
      legend: {
        layout: 'horizontal',
        itemStyle: {
          color: '#0C0C0C',
        },
        itemHoverStyle: {
          color: '#eee',
        },
        itemHiddenStyle: {
          color: '#0C0C0C',
        },
      },
      tooltip: {
        shared: !1,
        useHTML: !0,
        backgroundColor: 'transparent',
        borderWidth: 0,
        borderRadius: 0,
        formatter: function() {
          const { point } = this;

          return `
          <div class="chart-tip ${point.tipClass}">
            <span class="title">${point.name}</span>                    
            <div class='content'></div>
            <span style="color: ${point.color}; font-size: 20px;">●</span>
            <b>${point.percentage.toFixed(2)} %</b>
            (${numberFormat === 'number' ? Number(point.y).toLocaleString('pt-BR') : formatCurrency(point.y)})
          </div>
        `;
        },
        valuePrefix: 'R$ ',
        valueDecimals: 2,
        shadow: !1,
      },
      xAxis: {
        title: {
          text: '',
          style: {
            color: '#0C0C0C',
          },
        },
        categories: [],
        crosshair: true,
        gridLineColor: '#119FE3',
        labels: {
          style: {
            color: '#666',
          },
        },
        lineColor: '#aaa',
        minorGridLineColor: '#505053',
        tickColor: '#119FE3',
      },
      yAxis: {
        title: {
          text: '',
        },
        gridLineColor: '#E0E0E3',
        labels: {
          // format: 'R$ {value}',
          style: {
            color: '#666',
          },
        },
        lineColor: '#119FE3',
        minorGridLineColor: '#505053',
        tickColor: '#119FE3',
      },
      plotOptions: {
        candlestick: {
          lineColor: '#1F4F77',
        },
      },

      series: [],
      drilldown: [],
    },
    options,
  );

  chartOptions.series = refreshing || data === null ? [] : [...data.series];
  chartOptions.xAxis.categories = refreshing || data === null ? [] : [...data.categories];

  return (
    <>
      {refreshing ? (
         <Loading isDark={false} />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} style={{ margin: 20, heidth: 150 }} />
      )}
    </>
  );
}
