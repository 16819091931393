import React, { Component } from 'react';
import { withIonLifeCycle, IonLabel } from '@ionic/react';

import api from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formats';

import Card from '../../Card';

class CardCrescimentoFaturamentoAnual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      data: null,
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.setState({ refreshing: true });
    api
      .get('/faturamento/crescimento-receita-anual')
      .then(({ data }) => {
        const { TOTAL_VENDAS_ANO_ANTERIOR, TOTAL_VENDAS_ANO_ATUAL } = data[0];
        this.setState({
          refreshing: false,
          data: {
            TOTAL_VENDAS_ANO_ANTERIOR,
            TOTAL_VENDAS_ANO_ATUAL,
            series: [
              {
                data: [],
                dataLabels: {
                  format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y}</span><br/>' +
                    '<span style="font-size:16px;">%</span>' +
                    '</div>',
                },
                tooltip: {
                  valueSuffix: '%',
                },
              },
            ],
          },
        });
      })
      .catch(err => {
        this.setState({ refreshing: false });
      });
  }

  render() {
    const { data } = this.state;

    const diferenca = () => {
      let calculo =
        (data && data.TOTAL_VENDAS_ANO_ANTERIOR) - (data && data.TOTAL_VENDAS_ANO_ATUAL);
      if (calculo !== 0) {
        return formatCurrency(parseFloat(calculo));
      }
      return 'R$ 0,00';
    };

    return (
      <>
        <Card
          className="card card__primary"
          title="Crescimento de Faturamento Anual"
          dashboardCard>
          <div>
            <div className="main-section">
              <div className="item">
                <IonLabel
                  color="tertiary"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>
                  ANO ANTERIOR
                </IonLabel>
                <IonLabel
                  color="light"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>
                  {data && formatCurrency(data.TOTAL_VENDAS_ANO_ANTERIOR)}
                </IonLabel>
              </div>
              <div className="item">
                <IonLabel
                  color="tertiary"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>
                  ANO ATUAL
                </IonLabel>
                <IonLabel
                  color="light"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>
                  {data && formatCurrency(data.TOTAL_VENDAS_ANO_ATUAL)}
                </IonLabel>
              </div>
            </div>
            <div className="main-section">
              <div className="item">
                <IonLabel
                  color="tertiary"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>
                  DIFERENÇA EM R$
                </IonLabel>
                <IonLabel
                  color="light"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>
                  {diferenca()}
                </IonLabel>
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardCrescimentoFaturamentoAnual);
