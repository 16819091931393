import { ACTIVATION, RESTORE_DATA, LOGIN, LOGOUT, CLEAR_DATA } from './actions';

const INITIAL_STATE = {
  loading: true,
  activated: false,
  authenticated: false,
  empresaLicenciada: null,
};

export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIVATION:
      return {
        ...state,
        activated: true,
      };

    case RESTORE_DATA:
      return {
        ...state,
        loading: false,
        activated: action.activated,
        authenticated: action.authenticated,
      };

    case CLEAR_DATA:
      return {
        ...state,
        loading: false,
        activated: false,
        authenticated: false,
      };
    case LOGIN:
      return {
        ...state,
        authenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
      };

    default:
      return state;
  }
}
