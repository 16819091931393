import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { Plugins } from '@capacitor/core';
import _ from 'lodash';
import { formatDateApi } from '../utils/formats';
import enviroment from '../constants/enviroment';

const { Storage } = Plugins;

async function getBaseUrl() {
  const base = enviroment.urlApi;
  return `${base}/api/`;
}
const api = axios.create();

api.interceptors.request.use(
  async config => {
    const baseURL = await getBaseUrl();
    config.baseURL = baseURL;
    const { value } = await Storage.get({ key: 'usuarioToken' });
    if (value) {
      config.headers = _.merge(config.headers, {
        database: formatDateApi(new Date()),
        Authorization: `Bearer ${value}`,
      });
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default api;

export const onRefreshing = new BehaviorSubject();
