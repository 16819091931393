import React, { Component } from 'react';
import { startOfMonth, addDays } from 'date-fns';
import {
  IonButton,
  IonButtons,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  withIonLifeCycle,
} from '@ionic/react';
import api, { onRefreshing } from '../../../../services/api';
import Card from '../../Card';
import LineChart from '../../../Charts/LineChart';
import { formatDateApi } from '../../../../utils/formats';
import { formatCurrency } from '../../../../utils/formats';

class CardSaldoAcumulado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      period: '',
      data: null,
    };

    this.title = 'Saldo acumulado';
    this.subscriptionRefresh = null;

    this.asyncRequest = null;
  }

  componentDidMount() {
    this.setState({ period: '6 Meses' });

    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      this.setState({
        refreshing: true,
        data: { categories: [], series: [] },
      });
      const dataBase = new Date();
      const datafinal = dataBase;
      const datainicial = startOfMonth(addDays(dataBase, -150));

      this.asyncRequest = api
        .get('/financeiro/saldo-acumulado/', {
          headers: {
            datainicial: formatDateApi(datainicial),
            datafinal: formatDateApi(datafinal),
          },
        })
        .then(({ data }) => {
          const resultData = data.slice(-6);
          this.asyncRequest = null;
          const saldoAcumulado = [];
          const receita = [];
          const despesa = [];
          resultData
            .map(item => {
              const valor = item.RECEITA - item.DESPESA;
              receita.push(item.RECEITA);
              despesa.push(item.DESPESA);
              return valor;
            })
            .reduce((acumulado, saldoAtual) => {
              const somaValores = acumulado + saldoAtual;
              if (saldoAcumulado.length === 0) {
                saldoAcumulado.push(acumulado);
              }
              saldoAcumulado.push(somaValores);
              return somaValores;
            });
          this.setState({
            refreshing: false,
            data: {
              categories: resultData.map(item => `${item.MES}/${item.ANO}`),
              series: [
                {
                  name: 'Receita',
                  type: 'column',
                  data: receita,
                  color: '#119FE3',
                  borderColor: null,
                },
                {
                  name: 'Despesa',
                  type: 'column',
                  data: despesa,
                  color: '#f04141',
                  borderColor: null,
                },
                {
                  name: 'Saldo acumulado',
                  type: 'spline',
                  data: saldoAcumulado,
                  color: '#F9C94E',
                },
              ],
            },
          });
        });
    });
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
  }

  render() {
    const { period, data, refreshing, showModal } = this.state;
    const { style } = this.props;
    return (
      <>
        <IonModal
          isOpen={showModal}
          onDidDismiss={() => this.setState({ showModal: false })}>
          <IonHeader>
            <IonToolbar color="primary" style={{ alignSelf: 'center' }}>
              <IonButtons slot="start">
                <IonButton onClick={() => this.setState({ showModal: false })}>
                  <IonIcon
                    slot="icon-only"
                    icon={require('ionicons/icons')['arrowBack']}></IonIcon>
                </IonButton>
              </IonButtons>
              <IonTitle>{this.title}</IonTitle>
              <IonButton
                color="dark"
                slot="end"
                size="small"
                style={{ fontSize: 10, marginRight: 10 }}>
                6 Meses
              </IonButton>
            </IonToolbar>
          </IonHeader>
          {showModal ? (
            <>
              <IonGrid
                fixed={true}
                className="card__primary fadeIn"
                style={{ padding: 20 }}>
                <LineChart refreshing={refreshing} data={data} />
              </IonGrid>
              <IonContent>
                <IonList>
                  <IonItem>
                    <IonLabel>Input</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Toggle</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Radio</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Checkbox</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Input</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Toggle</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Radio</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Checkbox</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Input</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Toggle</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Radio</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Checkbox</IonLabel>
                  </IonItem>
                </IonList>
              </IonContent>
            </>
          ) : (
            <></>
          )}
        </IonModal>

        <Card
          title={this.title}
          className="card card__light"
          period={period}
          allowsFilter={false}
          style={style}>
          <LineChart
            refreshing={refreshing}
            data={data}
            options={{
              plotOptions: {
                column: {
                  dataLabels: {
                    style: {
                      color: '#666',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              },
              yAxis: {
                stackLabels: {
                  style: {
                    color: '#666',
                    fontWeight: 600,
                    border: 'none',
                  },
                  enabled: false,
                  formatter: function() {
                    if (this.total > 0) {
                      return formatCurrency(this.total);
                    }
                  },
                },
              },
            }}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardSaldoAcumulado);
