import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoutes = ({ component: Component, activated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps =>
        activated ? <Component {...matchProps} /> : <Redirect to="/activation" />
      }
    />
  );
};
