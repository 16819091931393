import React from 'react';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import Vendas from '../../../components/Cards/Dashboard/Vendas';
import Recebimentos from '../../../components/Cards/Dashboard/Recebimentos';
import Pagamentos from '../../../components/Cards/Dashboard/Pagamentos';
import TicketMedio from '../../../components/Cards/Dashboard/TicketMedio';
import Nfe from '../../../components/Cards/Dashboard/Nfe';
import VendasPorHora from '../../../components/Cards/Dashboard/VendasPorHora';
import VendasMensais from '../../../components/Cards/Dashboard/VendasMensais';
import Clientes from '../../../components/Cards/Dashboard/Clientes';

export default function Home() {
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <Vendas />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <Recebimentos />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <Pagamentos />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <Nfe />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <TicketMedio />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <Clientes />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="12" size="12">
              <VendasMensais />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="12" size="12">
              <VendasPorHora />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
