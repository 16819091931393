import React, { Component } from 'react';
import { IonCol, IonLabel, withIonLifeCycle } from '@ionic/react';
import { BehaviorSubject } from 'rxjs';

import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi } from '../../../../utils/formats';

import Card from '../../Card';

const onSelect = new BehaviorSubject();

class CardServicosTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      period: '',
      data: [
        {
          TOTAL: 0,
          FECHADAS: 0,
          ABERTAS: 0,
          CANCELADAS: 0,
        },
      ],
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());
      onSelect.next({ dataInicial, dataFinal });
    });
    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({ refreshing: true });
        this.asyncRequest = api.get('/servico/totais', { headers }).then(({ data }) => {
          this.asyncRequest = null;
          this.setState({ data, refreshing: false });
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.periodo !== prevProps.periodo) {
      const headers = {};
      headers.dataInicial = this.props.dateRange.dataInicial;
      headers.dataFinal = this.props.dateRange.dataFinal;
      this.setState({ period: this.props.periodo });
      if (headers.dataInicial && headers.dataFinal) {
        this.setState({ refreshing: true });
        api.get('/servico/totais', { headers }).then(({ data }) => {
          this.setState({ data, refreshing: false });
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { period, data } = this.state;
    return (
      <>
        <Card
          className="card card__light"
          title="Totais de Ordens de Serviço"
          period={period}
          onChangeDate={this.onChangeDate}>
          <div>
            <IonCol className="container-totalizador">
              <div className="d-flex" style={{ marginInline: 30 }}>
                <IonLabel color="dark" className="label-totalizador">
                  Totais de OS:
                </IonLabel>
                <IonLabel color="primary" className="value">
                  {data && data[0].TOTAL}
                </IonLabel>
              </div>
              <div className="d-flex" style={{ marginInline: 30 }}>
                <IonLabel color="dark" className="label-totalizador">
                  Fechadas:
                </IonLabel>
                <IonLabel color="primary" className="value">
                  {data && data[0].FECHADAS}
                </IonLabel>
              </div>
              <div className="d-flex" style={{ marginInline: 30 }}>
                <IonLabel color="dark" className="label-totalizador">
                  Abertas:
                </IonLabel>
                <IonLabel color="primary" className="value">
                  {data && data[0].ABERTAS}
                </IonLabel>
              </div>
              <div className="d-flex" style={{ marginInline: 30 }}>
                <IonLabel color="dark" className="label-totalizador">
                  Canceladas:
                </IonLabel>
                <IonLabel color="primary" className="value">
                  {data && data[0].CANCELADAS}
                </IonLabel>
              </div>
            </IonCol>
          </div>
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardServicosTotal);
