import styled from 'styled-components';
export const TitleApp = styled.h1`
  text-align: center;
  font-size: 20px;
  font-weight: 600;

  span {
    font-weight: normal;
  }
`;

export const Container = styled.form`
  padding: 24px;
`;
