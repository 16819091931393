import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { BehaviorSubject } from 'rxjs';
import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi, formatCurrency } from '../../../../utils/formats';
import Card from '../../Card';
import PieChart from '../../../Charts/PieChart';

const onSelect = new BehaviorSubject();

class VendasPorGrupoServico extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      period: '',
      data: null,
    };

    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;

    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());

      onSelect.next({ dataInicial, dataFinal });
    });

    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({
          refreshing: true,
          data: {
            series: [],
          },
        });
        this.asyncRequest = api
          .get('servico/gruposervico', { headers })
          .then(({ data }) => {
            this.asyncRequest = null;
            const listaGrupos = data.slice(0, 5);
            const listaOutrosGrupos = data.slice(5, data.length);
            const grupos = listaGrupos.map(({ GRUPOCOD, GRUPO, VALORTOTAL }) => ({
              name: `${GRUPOCOD} - ${GRUPO}`,
              y: VALORTOTAL,
            }));

            if (listaOutrosGrupos.length > 0) {
              grupos.push({
                name: 'OUTROS',
                y: listaOutrosGrupos.reduce(
                  (total, venda) => total + Number(venda.VALORTOTAL),
                  0,
                ),
                sliced: true,
                selected: true,
              });
            }
            this.setState({
              data:
                data.length > 0
                  ? {
                      series: [
                        {
                          name: 'grupos',
                          colorByPoint: true,
                          data: [...grupos],
                        },
                      ],
                    }
                  : {
                      series: [],
                    },
              refreshing: false,
            });
          });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.periodo !== prevProps.periodo) {
      const headers = {};
      headers.dataInicial = this.props.dateRange.dataInicial;
      headers.dataFinal = this.props.dateRange.dataFinal;
      this.setState({ period: this.props.periodo });
      if (headers.dataInicial && headers.dataFinal) {
        this.setState({
          refreshing: true,
          data: {
            series: [],
          },
        });
        api.get('servico/gruposervico', { headers }).then(({ data }) => {
          const listaGrupos = data.slice(0, 5);
          const listaOutrosGrupos = data.slice(5, data.length);
          const grupos = listaGrupos.map(({ GRUPOCOD, GRUPO, VALORTOTAL }) => ({
            name: `${GRUPOCOD} - ${GRUPO}`,
            y: VALORTOTAL,
          }));
          if (listaOutrosGrupos.length > 0) {
            grupos.push({
              name: 'OUTROS',
              y: listaOutrosGrupos.reduce(
                (total, venda) => total + Number(venda.VALORTOTAL),
                0,
              ),
              sliced: true,
              selected: true,
            });
          }
          this.setState({
            data:
              data.length > 0
                ? {
                    series: [
                      {
                        name: 'grupos',
                        colorByPoint: true,
                        data: [...grupos],
                      },
                    ],
                  }
                : {
                    series: [],
                  },
            refreshing: false,
          });
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();

    //if (this.asyncRequest) this.asyncRequest.cancel();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { period, data, refreshing } = this.state;
    return (
      <>
        <Card
          className="card card__primary"
          title="Vendas por grupo de serviços"
          period={period}
          onChangeDate={this.onChangeDate}>
          <PieChart
            refreshing={refreshing}
            data={data}
            options={{
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    style: {
                      color: '#fff',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              },
            }}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(VendasPorGrupoServico);
