import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import { IonList, IonItem, IonLabel, IonImg, withIonLifeCycle } from '@ionic/react';

import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi, formatCurrency } from '../../../../utils/formats';

import Card from '../../Card';
import Loading from '../../../Loading';
import Empty from '../../../Empty';

const onSelect = new BehaviorSubject();

class CardVendasPorServicos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      period: '',
      data: null,
    };

    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;

    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());
      onSelect.next({ dataInicial, dataFinal });
    });

    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({ refreshing: true, data: null });
        this.asyncRequest = api
          .get('servico/servicos', {
            headers,
          })
          .then(({ data }) => {
            this.asyncRequest = null;
            this.setState({ refreshing: false, data });
          });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.periodo !== prevProps.periodo) {
      const headers = {};
      headers.dataInicial = this.props.dateRange.dataInicial;
      headers.dataFinal = this.props.dateRange.dataFinal;
      this.setState({ period: this.props.periodo });
      if (headers.dataInicial && headers.dataFinal) {
        this.setState({ refreshing: true, data: null });
        api.get('servico/servicos', { headers }).then(({ data }) => {
          this.asyncRequest = null;
          this.setState({ refreshing: false, data });
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { period, data, refreshing } = this.state;
    return (
      <>
        <Card
          className="card card__primary"
          title="Serviços mais vendidos"
          period={period}
          onChangeDate={this.onChangeDate}
          style={{ minHeight: 333 }}>
          {refreshing || data === null ? (
            <Loading isDark={true} />
          ) : (
            <>
              {data.length > 0 ? (
                <IonList>
                  {console.log(data)}
                  {data.map(
                    ({ SERVICO, SERVICO_ID, QTD_ITENS, VALOR_TOTAL, TICKET_MEDIO }) => (
                      <IonItem key={SERVICO_ID} style={{ margin: 0, padding: 0 }}>
                        <IonImg
                          slot="start"
                          style={{ width: 24 }}
                          src={require('../../../../assets/svg/icon-produto-alternativo.svg')}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <div>
                            <IonLabel
                              color="light"
                              style={{ whiteSpace: 'pre-wrap', fontSize: 12 }}>
                              {`${SERVICO_ID} - ${SERVICO}`}
                            </IonLabel>
                            <IonLabel color="light" style={{ fontSize: 10 }}>
                              qtd. vendas: {QTD_ITENS}
                            </IonLabel>
                          </div>
                          <div>
                            <IonLabel
                              color="light"
                              style={{
                                fontSize: 14,
                                textAlign: 'right',
                                fontWeight: 'bold',
                              }}>
                              {formatCurrency(VALOR_TOTAL)}
                            </IonLabel>
                            <IonLabel
                              style={{
                                padding: 2,
                                color: '#FFFFFF',
                                textAlign: 'right',
                                fontSize: 10,
                              }}>
                              ticket méd.: {formatCurrency(TICKET_MEDIO)}
                            </IonLabel>
                          </div>
                        </div>
                      </IonItem>
                    ),
                  )}
                </IonList>
              ) : (
                <Empty icon="box" />
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardVendasPorServicos);
