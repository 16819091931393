import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import api from '../../../../services/api';
import Card from '../../Card';
import SectionItem from '../../../Section/SectionItem';
import SectionItemCurrency from '../../../Section/SectionItemCurrency';
import { withIonLifeCycle } from '@ionic/react';

const onSelect = new BehaviorSubject();

class CardEstoqueFinanceiro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      data: {
        totalProdutos: 0,
        totalProdutosPositivos: 0,
      },
      period: 'Hoje',
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionSelect = onSelect.subscribe(tipo => {
      this.setState({ refreshing: true, actionSelected: tipo });
      this.asyncRequest = api.get('/estoque/estoque-financeiro').then(({ data }) => {
        this.asyncRequest = null;
        this.setState({ data, refreshing: false });
      });
    });
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
  }

  render() {
    const { period, data, refreshing } = this.state;
    return (
      <>
        <Card
          title="Estoque financeiro"
          period={period}
          allowsFilter={false}
          dashboardCard>
          <SectionItem
            title="Total de produtos"
            refreshing={refreshing}
            total={data.totalProdutos}
          />
          <SectionItem
            title="Total de produtos com estoque positivo"
            refreshing={refreshing}
            total={data.totalProdutosPositivos}
          />
          <SectionItem
            title="Saldo total de produtos em estoque"
            refreshing={refreshing}
            total={data.saldoProdutosPositivos}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardEstoqueFinanceiro);
