import React from 'react';
import { IonSpinner } from '@ionic/react';

import ContainerLoading from './style';

export default function Loading({ isDark, title }) {
  return (
    <ContainerLoading>
      <IonSpinner
        name="crescent"
        style={{
          height: 54,
          color: isDark ? '#FFF' : '#666',
          width: '100%',
          alignSelf: 'center',
        }}
      />
      <span style={{ color: isDark ? '#FFF' : '#666', marginTop: 20, fontSize: 15 }}>
        {title ? title : 'Carregando..'}
      </span>
    </ContainerLoading>
  );
}
