import React, { useState } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonItem,
  IonImg,
  IonActionSheet,
  IonButton,
  IonIcon,
  IonDatetime,
  IonButtons,
} from '@ionic/react';
import { addDays, startOfMonth, endOfMonth, endOfWeek, startOfWeek } from 'date-fns';

import { formatDateApi } from '../../../utils/formats';

export default function Card({
  children,
  style,
  className,
  title,
  period = 'Hoje',
  datePicker,
  dateYear,
  allowsFilter = true,
  actionsSchet,
  onChangeDate,
  iconName,
  dashboardCard,
}) {
  const [showActionSheet, setShowActionSheet] = useState(false);
  const dataBase = new Date();
  const formattedDateString = new Date().toLocaleString('en-CA', {
    dateStyle: 'short',
  });

  const renderDateInput = () => {
    if (dateYear) {
      return (
        <IonDatetime
          style={{ border: 'none', width: '100%' }}
          presentation="date"
          displayFormat="YYYY"
          preferWheel={true}
          doneText="Confirmar"
          cancelText="Cancelar"
          value={dateYear}
          onIonChange={e => onChangeDate(e.detail.value)}
        />
      );
    }
    return (
      <input
        style={{ border: 'none', height: 21.5 }}
        type="date"
        id="date"
        name="date"
        defaultValue={formattedDateString}
        onChange={e => onChangeDate(e.target.value)}
      />
    );
  };

  return dashboardCard ? (
    <>
      <IonCard className={className ? `${className} fadeIn` : 'card fadeIn'}>
        <IonCardHeader>
          <IonItem>
            {iconName && (
              <IonImg
                slot="start"
                style={{ width: 32 }}
                src={iconName && require(`../../../assets/svg/${iconName}.svg`)}
              />
            )}
            <IonCardTitle>{title}</IonCardTitle>
          </IonItem>
        </IonCardHeader>
        <IonCardContent style={{ paddingBottom: 24 }}>
          <div className="section">{children}</div>
        </IonCardContent>
      </IonCard>
    </>
  ) : (
    <>
      <IonCard
        style={style}
        className={className ? `${className} fadeIn` : 'card fadeIn'}>
        <IonCardHeader>
          <IonRow>
            <IonCol style={{ alignSelf: 'center' }}>
              <IonCardTitle>{title}</IonCardTitle>
            </IonCol>
            <IonCol style={{ alignSelf: 'center' }}>
              {datePicker ? (
                <IonButton
                  size="small"
                  color="tertiary"
                  expand="block"
                  style={{ fontSize: 10 }}>
                  {renderDateInput()}
                </IonButton>
              ) : period && allowsFilter ? (
                <IonButton
                  size="small"
                  color="tertiary"
                  expand="block"
                  style={{ fontSize: 10 }}
                  onClick={() => setShowActionSheet(true)}>
                  {period}

                  <IonIcon icon={require('ionicons/icons')['arrowDownOutline']}></IonIcon>
                </IonButton>
              ) : (
                <IonButton
                  size="small"
                  color="tertiary"
                  expand="block"
                  style={{ fontSize: 10 }}>
                  {period}
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonCardHeader>
        <IonCardContent>
          <div className="section">{children}</div>
          <IonActionSheet
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            buttons={
              actionsSchet
                ? actionsSchet
                : [
                    {
                      text: 'Hoje',
                      handler: () => {
                        onChangeDate({
                          dataInicial: formatDateApi(dataBase),
                          dataFinal: formatDateApi(dataBase),
                          periodo: 'Hoje',
                        });
                      },
                    },
                    {
                      text: 'Ontem',
                      handler: () => {
                        onChangeDate({
                          dataInicial: formatDateApi(addDays(dataBase, -1)),
                          dataFinal: formatDateApi(addDays(dataBase, -1)),
                          periodo: 'Ontem',
                        });
                      },
                    },
                    {
                      text: 'Últimos 7 dias',
                      handler: () => {
                        onChangeDate({
                          dataInicial: formatDateApi(addDays(dataBase, -7)),
                          dataFinal: formatDateApi(dataBase),
                          periodo: 'Últimos 7 dias',
                        });
                      },
                    },
                    {
                      text: 'Esta semana',
                      handler: () => {
                        onChangeDate({
                          dataInicial: formatDateApi(startOfWeek(dataBase)),
                          dataFinal: formatDateApi(endOfWeek(dataBase)),
                          periodo: 'Esta semana',
                        });
                      },
                    },
                    {
                      text: 'Últimos 30 dias',
                      handler: () => {
                        onChangeDate({
                          dataInicial: formatDateApi(addDays(dataBase, -30)),
                          dataFinal: formatDateApi(dataBase),
                          periodo: 'Últimos 30 dias',
                        });
                      },
                    },
                    {
                      text: 'Este mês',
                      handler: () => {
                        onChangeDate({
                          dataInicial: formatDateApi(startOfMonth(dataBase)),
                          dataFinal: formatDateApi(endOfMonth(dataBase)),
                          periodo: 'Este mês',
                        });
                      },
                    },
                    {
                      text: 'Último mês',
                      handler: () => {
                        const ultimoDiaMesAnterior = addDays(startOfMonth(dataBase), -1);

                        onChangeDate({
                          dataInicial: formatDateApi(addDays(ultimoDiaMesAnterior, -30)),
                          dataFinal: formatDateApi(ultimoDiaMesAnterior),
                          periodo: 'Último mês ',
                        });
                      },
                    },
                  ]
            }></IonActionSheet>
        </IonCardContent>
      </IonCard>
    </>
  );
}
