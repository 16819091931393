import React from 'react';
import './styles.css';
import { IonImg, IonText, IonSkeletonText } from '@ionic/react';
import { formatCurrency } from '../../utils/formats';

export default function SectionItemCurrency({
  refreshing,
  icon,
  count,
  title,
  subtitle,
  total,
}) {
  return (
    <>
      <div className="container">
        {icon && (
          <IonImg
            slot="start"
            style={{ width: 32 }}
            src={icon && require(`../../assets/svg/icon-${icon}.svg`)}
          />
        )}
        {/* <BadgeCounter icon={icon} count={refreshing ? '...' : count} /> */}
        <div className="inner">
          <div className="info" style={{ alignSelf: 'center' }}>
            <IonText color="primary" className="info-title">
              {title}
            </IonText>
            {refreshing ? (
              <IonSkeletonText
                style={{ width: '120px', height: '12px', borderRadius: 10 }}
                animated
              />
            ) : (
              <>
                {subtitle !== undefined ? (
                  <IonText className="info-subtitle">{subtitle}</IonText>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          {refreshing ? (
            <IonSkeletonText
              style={{
                width: '120px',
                height: '16px',
                alignSelf: 'center',
                borderRadius: 10,
              }}
              animated
            />
          ) : (
            <IonText className="total">{formatCurrency(total)}</IonText>
          )}
        </div>
      </div>
    </>
  );
}
