import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonSplitPane, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Plugins } from '@capacitor/core';
import jwt_decode from 'jwt-decode';
import { PrivateRoutes } from './routers/PrivateRoutes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

import GlobalStyle from './styles/global';

import Tabs from './pages/Main/Tabs';
import Login from './pages/Login';
import Activation from './pages/Activation';
import AddEmpresa from './pages/AddEmpresa';

import * as AuthActions from './store/modules/auth/actions';

const { StatusBar, Storage } = Plugins;

const App = ({ auth, restoreData }) => {
  document.oncontextmenu = document.body.oncontextmenu = () => {
    return false;
  };
  const [closeMenu, setCloseMenu] = useState(true);

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  useEffect(() => {
    StatusBar.setBackgroundColor({ color: '#002ebe' });

    const bootstrapAsync = async () => {
      const { value: empresaLicenciada } = await Storage.get({
        key: 'empresaLicenciada',
      });
      const { value: usuarioToken } = await Storage.get({
        key: 'usuarioToken',
      });

      let activated;
      let authenticated;

      if (usuarioToken) {
        const usuarioTokenDecoded = jwt_decode(usuarioToken);
        if (usuarioTokenDecoded.exp < Date.now() / 1000) {
          await Storage.remove({
            key: 'usuarioToken',
          });
          authenticated = false;
        } else {
          authenticated = usuarioToken !== null;
        }
      }

      if (empresaLicenciada) {
        activated = empresaLicenciada !== null;
      }

      restoreData(activated, authenticated);
    };

    bootstrapAsync();
  }, [restoreData]);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <IonRouterOutlet id="main" animated>
            <Switch>
              <Route exact path="/activation" component={Activation} />
              <PrivateRoutes
                exact
                path="/login"
                activated={auth.activated}
                component={Login}
              />
              <PrivateRoutes
                exact
                path="/add-empresa"
                activated={auth.activated}
                component={AddEmpresa}
              />
              <Route
                path="/tabs"
                render={props =>
                  !auth.authenticated ? (
                    <Redirect to="/login" />
                  ) : (
                    <Tabs {...props} handleCloseMenu={handleCloseMenu} />
                  )
                }
              />
              <Redirect exact from="/" to="tabs" />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
      <GlobalStyle />
    </IonApp>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
