import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';

import api from '../../../../services/api';
import { synchronizeData } from '../../../../utils/synchronizeData';
import { formatCurrency, formatDateApi } from '../../../../utils/formats';

import Card from '../../Card';
import BarCHart from '../../../Charts/LineChart';
import Empty from '../../../Empty';

class CardVendasNfeNfceChart extends Component {
  constructor() {
    super();
    this.state = {
      noData: true,
      period: '',
      refreshing: false,
      data: { categories: [], series: [] },
      date: new Date().toISOString(),
    };
    this.headers = { pickedDate: new Date() };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
    this.monthNames = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];
  }

  apiCall = () => {
    this.setState({ refreshing: true });
    api.get('/dashboard/vendas-mes').then(({ data }) => {
      this.asyncRequest = null;
      const { vendasNfce, vendasNfe, vendasNaoFiscal } = data;
      this.setState({
        noData: vendasNfce.length > 0 || vendasNfe.length > 0 ? false : true,
        refreshing: false,
        data: {
          categories: this.monthNames,
          series: [
            {
              name: 'NFC-e',
              type: 'bar',
              data: vendasNfce.map(nfce => nfce),
              color: '#f04141',
              borderColor: null,
            },
            {
              name: 'NF-e',
              type: 'bar',
              data: vendasNfe.map(nfe => nfe),
              color: '#119FE3',
              borderColor: null,
            },
            {
              name: 'Outros',
              type: 'bar',
              data: vendasNaoFiscal.map(naoFiscal => naoFiscal),
              color: '#a2a4ab',
              borderColor: null,
            },
          ],
        },
      });
    });
  };

  apiCallUpdate = () => {
    this.setState({ refreshing: true });
    api.get('/dashboard/vendas-mes', { headers: this.headers }).then(({ data }) => {
      this.asyncRequest = null;
      const { vendasNfce, vendasNfe, vendasNaoFiscal } = data;
      this.setState({
        noData: vendasNfce.length > 0 || vendasNfe.length > 0 ? false : true,
        refreshing: false,
        data: {
          categories: this.monthNames,
          series: [
            {
              name: 'NFC-e',
              type: 'bar',
              data: vendasNfce.map(nfce => nfce),
              color: '#f04141',
              borderColor: null,
            },
            {
              name: 'NF-e',
              type: 'bar',
              data: vendasNfe.map(nfe => nfe),
              color: '#119FE3',
              borderColor: null,
            },
            {
              name: 'Outros',
              type: 'bar',
              data: vendasNaoFiscal.map(naoFiscal => naoFiscal),
              color: '#a2a4ab',
              borderColor: null,
            },
          ],
        },
      });
    });
  };

  componentDidMount() {
    this.apiCall();
    synchronizeData(this.apiCallUpdate);
  }

  componentDidUpdate() {
    if (this.headers.pickedDate !== this.state.date) {
      this.headers.pickedDate = this.state.date;
      if (this.headers.pickedDate) {
        this.apiCallUpdate();
      }
    }
  }

  onChangeDate = date => {
    this.setState({ ...this.state, date });
  };

  render() {
    const { refreshing, period, data, date, noData } = this.state;
    const cardStyle = 'card card__light';

    return (
      <Card
        title="Vendas Mensais"
        className={cardStyle}
        period={period}
        onChangeDate={this.onChangeDate}
        datePicker
        dateYear={date}
        allowsFilter={false}>
        {!noData ? (
          <BarCHart
            refreshing={refreshing}
            data={data}
            options={{
              plotOptions: {
                bar: {
                  dataLabels: {
                    style: {
                      color: '#666',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              },
            }}
          />
        ) : (
          <Empty icon="barChart" cardStyle={cardStyle} />
        )}
      </Card>
    );
  }
}

export default withIonLifeCycle(CardVendasNfeNfceChart);
