import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import { IonList, IonItem, IonLabel, IonImg, withIonLifeCycle } from '@ionic/react';

import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi, formatCurrency } from '../../../../utils/formats';

import Card from '../../Card';
import Loading from '../../../Loading';
import Empty from '../../../Empty';

const onSelect = new BehaviorSubject();

class CardComprasPorCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      period: '',
      data: null,
    };

    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());
      onSelect.next({ dataInicial, dataFinal });
    });

    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({ refreshing: true });
        this.asyncRequest = api
          .get('faturamento/cliente', {
            headers
          })
          .then(({ data }) => {
            this.asyncRequest = null;
            this.setState({ data: data.slice(0, 5), refreshing: false });
          });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.periodo !== prevProps.periodo) {
      const headers = {};
      headers.dataInicial = this.props.dateRange.dataInicial
      headers.dataFinal = this.props.dateRange.dataFinal
      this.setState({ period: this.props.periodo });
      if (headers.dataInicial && headers.dataFinal) {
        this.setState({ refreshing: true });
        api.get('faturamento/cliente', { headers }).then(({ data }) => {
          this.asyncRequest = null;
          this.setState({ data: data.slice(0, 5), refreshing: false });
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { period, data, refreshing } = this.state;
    return (
      <>
        <Card
          className="card card__primary"
          title="Clientes que mais compraram"
          period={period}
          onChangeDate={this.onChangeDate}
          style={{ minHeight: 333 }}
        >
          {refreshing || data === null ? (
            <Loading isDark={true} />
          ) : (
            <>
              {data.length > 0 ? (
                <IonList>
                  {data.map((
                    {
                      CLIENTE,
                      CLIENTE_ID,
                      QTD_TOTAL_NOTAS,
                      VALOR_TOTAL,
                      TICKET_MEDIO
                    }
                  ) => (
                    <IonItem key={CLIENTE_ID} style={{ margin: 0, padding: 0 }}>
                      <IonImg
                        slot="start"
                        style={{ width: 24 }}
                        src={require('../../../../assets/svg/icon-cliente-alternativo.svg')}
                      />
                      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <IonLabel color="light" style={{ whiteSpace: 'normal', fontSize: 12 }}>
                            {CLIENTE ? `${CLIENTE_ID} - ${CLIENTE}` : 'CONSUMIDOR NÃO DEFINIDO'}
                          </IonLabel>
                          <IonLabel color="light" style={{ fontSize: 10 }}>
                            qtd. notas: {QTD_TOTAL_NOTAS}
                          </IonLabel>
                        </div>
                        <div>
                          <IonLabel
                            color="light"
                            style={{ textAlign: 'right', fontSize: 14, fontWeight: 'bold' }}>
                            {formatCurrency(VALOR_TOTAL)}
                          </IonLabel>
                          <IonLabel
                            style={{
                              padding: 2,
                              color: '#FFFFFF',
                              textAlign: 'right',
                              fontSize: 10
                            }}
                          >
                            ticket méd.: {formatCurrency(TICKET_MEDIO)}
                          </IonLabel>
                        </div>
                      </div>
                    </IonItem>
                  ))}
                </IonList>
              ) : (
                <Empty icon="box" />
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardComprasPorCliente);
