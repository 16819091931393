import React, { useState } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import { formatDateApi } from '../../../utils/formats';
import Card from '../../../components/Cards/Card';
import CardServicosTotalizadores from '../../../components/Cards/Servicos/TotalizadoresServico';
import CardServicosTotal from '../../../components/Cards/Servicos/TotalServico';
import CardCrescimentoFaturamentoAnual from '../../../components/Cards/Servicos/CrescimentoFaturamentoAnual';
import CardMetaFaturamentoAnual from '../../../components/Cards/Servicos/MetaFaturamentoAnual';
import CardComparativoFaturamentoMeta from '../../../components/Cards/Servicos/ComparativoFaturamentoMeta';
import CardComparativoServicosAtendente from '../../../components/Cards/Servicos/ComparativoServicosAtendente';
import CardServicosPorAtendente from '../../../components/Cards/Servicos/ServicoPorAtendente';
import VendasPorGrupoServico from '../../../components/Cards/Servicos/VendasPorGrupoServico';
import CardVendasPorTipoPagamento from '../../../components/Cards/Servicos/VendasPorTipoPagamento';
import CardServicoMaisVendidos from '../../../components/Cards/Servicos/ServicosMaisVendidos';
import CardClientesQueMaisCompraram from '../../../components/Cards/Servicos/ClientesQueMaisCompraram';
import CardComparativoVendasSemanal from '../../../components/Cards/Servicos/ComparativoVendasSemanal';

export default function Servicos() {
  const [period, setPeriod] = useState('Hoje');
  const [dateRange, setDateRange] = useState({
    dataInicial: formatDateApi(new Date()),
    dataFinal: formatDateApi(new Date()),
  });

  const onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    setDateRange({ dataInicial, dataFinal });
    setPeriod(periodo);
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <Card
                title="Alterar Data Em Lote"
                period={period}
                onChangeDate={onChangeDate}
                style={{ maxHeight: 72 }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <CardServicosTotalizadores dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardCrescimentoFaturamentoAnual />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardMetaFaturamentoAnual />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="12" size="12">
              <CardServicosTotal dateRange={dateRange} periodo={period} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="12" size="12">
              <CardComparativoFaturamentoMeta />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="12" size="12">
              <CardComparativoServicosAtendente />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <CardServicosPorAtendente dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <VendasPorGrupoServico dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardVendasPorTipoPagamento dateRange={dateRange} periodo={period} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="6" size="12">
              <CardServicoMaisVendidos dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="6" size="12">
              <CardClientesQueMaisCompraram dateRange={dateRange} periodo={period} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CardComparativoVendasSemanal />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
