import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import { IonList, IonItem, IonLabel, IonImg, withIonLifeCycle } from '@ionic/react';
import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi, formatCurrency } from '../../../../utils/formats';
import Card from '../../Card';
import Loading from '../../../Loading';
import Empty from '../../../Empty';

const onSelect = new BehaviorSubject();

class CardMaioresDevedores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      period: '',
      data: null,
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());
      onSelect.next({ dataInicial, dataFinal });
    });

    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({ refreshing: true });
        this.asyncRequest = api
          .get('financeiro/maiores-devedores', {
            headers,
          })
          .then(({ data }) => {
            this.asyncRequest = null;
            this.setState({ data: data.slice(0, 5), refreshing: false });
          });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { data, refreshing } = this.state;
    return (
      <>
        <Card
          className="card card__primary"
          title="Maiores devedores"
          dashboardCard
          style={{ minHeight: 333 }}>
          {refreshing || data === null ? (
            <Loading isDark={true} />
          ) : (
            <>
              {data.length > 0 ? (
                <IonList>
                  {data.map(({ cliente, clienteId, qtdTotalDocumentos, valorTotal }) => (
                    <IonItem key={clienteId} style={{ margin: 0, padding: 0 }}>
                      <IonImg
                        slot="start"
                        style={{ width: 24 }}
                        src={require('../../../../assets/svg/icon-cliente-alternativo.svg')}
                      />

                      <div>
                        <IonLabel
                          color="light"
                          style={{ whiteSpace: 'normal', fontSize: 10 }}>
                          {`${clienteId} - ${cliente}`}
                        </IonLabel>
                        <IonLabel color="light" style={{ fontSize: 10 }}>
                          Qtd. Duplicatas: {qtdTotalDocumentos.toFixed(2)}
                        </IonLabel>
                      </div>

                      <IonLabel
                        slot="end"
                        color="light"
                        style={{
                          textAlign: 'right',
                          margin: 0,
                          padding: 0,
                          fontSize: 14,
                          fontWeight: 'bold',
                        }}>
                        {formatCurrency(valorTotal)}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              ) : (
                <Empty icon="box" />
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardMaioresDevedores);
