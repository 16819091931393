import React, { useState } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import { formatDateApi } from '../../../utils/formats';
import CardFinanceiroTotalizadores from '../../../components/Cards/Financeiro/Totalizadores';
import CardSaldoAcumulado from '../../../components/Cards/Financeiro/SaldoAcumulado';
import CardComparativoFinanceiro from '../../../components/Cards/Financeiro/ComparativoFinanceiro';
import CardRecebimentosFinanceiro from '../../../components/Cards/Financeiro/Recebimentos';
import CardPagamentosFinanceiro from '../../../components/Cards/Financeiro/Pagamentos';
import CardInadimplenciaAcumulada from '../../../components/Cards/Financeiro/InadimplenciaAcumulada';
import CardRecebimentoPorTipoPagamento from '../../../components/Cards/Financeiro/RecebimentoPorTipoPagamento';
import CardMaioresCredores from '../../../components/Cards/Financeiro/MaioresCredores';
import CardMaioresDevedores from '../../../components/Cards/Financeiro/MaioresDevedores';
import Card from '../../../components/Cards/Card';

export default function Financeiro() {
  const [period, setPeriod] = useState('Hoje');
  const [dateRange, setDateRange] = useState({
    dataInicial: formatDateApi(new Date()),
    dataFinal: formatDateApi(new Date()),
  });

  const onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    setDateRange({ dataInicial, dataFinal });
    setPeriod(periodo);
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <Card
                title="Alterar Data Em Lote"
                period={period}
                onChangeDate={onChangeDate}
                style={{ maxHeight: 72 }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <CardFinanceiroTotalizadores dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="6">
              <CardRecebimentosFinanceiro dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="6">
              <CardPagamentosFinanceiro dateRange={dateRange} periodo={period} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="8" size="12">
              <CardSaldoAcumulado />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardInadimplenciaAcumulada dateRange={dateRange} periodo={period} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <CardMaioresCredores />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardRecebimentoPorTipoPagamento dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardMaioresDevedores />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CardComparativoFinanceiro />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
