import React, { useState, useEffect, useRef } from 'react';
import api from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formats';
import Card from '../../Card';
import LineChart from '../../../Charts/LineChart';
import CheckBoxLabel from '../../../CheckBoxLabel/CheckBoxLabel';
import Empty from '../../../Empty';

const CardComparativoVendasVendedor = props => {
  const [noData, setNoData] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [data, setData] = useState({ categories: [], series: [] });
  const [checked, setChecked] = useState(false);
  const chartRef = useRef(null);

  const getRandomColor = () => {
    const letters = '56789ABCD';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 9)];
    }
    return color;
  };

  useEffect(() => {
    setRefreshing(true);
    api.get('/faturamento/comparativo-vendas-vendedor').then(({ data }) => {
      if (data.length) {
        const vendedoresId = [];
        const categoriasDuplicadas = data.map(item => item.SAI_DATA);
        const categories = [...new Set(categoriasDuplicadas)];
        const series = [];
        for (const item of data) {
          if (!vendedoresId.includes(item.VEN_COD)) {
            vendedoresId.push(item.VEN_COD);
            const valores = [];
            for (const categorie of categories) {
              const valorResult = data.find(
                valor =>
                  (valor.SAI_DATA === categorie) && (valor.VEN_COD === item.VEN_COD),
              );
              if (valorResult) {
                valores.push(valorResult.SAI_TOTAL);
              } else {
                valores.push(0);
              }
            }
            series.push({
              name: item.VEN_NOME,
              color: getRandomColor(),
              borderColor: null,
              data: valores,
              visible: true,
            });
          }
        }
        setRefreshing(false);
        setData({
          categories,
          series,
        });
        setNoData(false);
      }
    });
  }, []);

  const visualizarTodos = visualizar => {
    setChecked(visualizar);
    setData({
      ...data,
      series: data?.series.map(serie => {
        return {
          ...serie,
          visible: !visualizar,
        };
      }),
    });
  };
  const cardStyle = 'card card__light';

  return (
    <>
      <Card
        title={'Comparativo de vendas por vendedor'}
        className={cardStyle}
        period={'Últimos 6 Meses'}
        allowsFilter={false}
        style={props.style}>
        {!noData ? (
          <LineChart
            chartRef={chartRef}
            refreshing={refreshing}
            data={data}
            options={{
              chart: {
                type: 'spline',
              },
              plotOptions: {
                area: {
                  fillOpacity: 0.5,
                },
                spline: {
                  stacking: 'normal',
                  dataLabels: {
                    style: {
                      color: '#666',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              },
              yAxis: {
                stackLabels: {
                  style: {
                    color: '#666',
                    fontWeight: 600,
                    border: 'none',
                  },
                  enabled: false,
                  formatter: function() {
                    if (this.total > 0) {
                      return formatCurrency(this.total);
                    }
                  },
                },
              },
            }}
          />
        ) : (
          <Empty icon="lineChart" cardStyle={cardStyle} />
        )}
        <div>
          <CheckBoxLabel checked={checked} visualizarTodos={visualizarTodos} />
        </div>
      </Card>
    </>
  );
};

export default CardComparativoVendasVendedor;
