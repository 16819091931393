import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { BehaviorSubject } from 'rxjs';

import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi, formatCurrency } from '../../../../utils/formats';

import Card from '../../Card';
import SectionItemCurrency from '../../../Section/SectionItemCurrency';

const onSelect = new BehaviorSubject();

class CardServicosTotalizadores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      period: '',
      data: {
        vendas: {},
        cancelamentos: {},
        devolucoes: {},
        saldoFaturamento: 0,
      },
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());
      onSelect.next({ dataInicial, dataFinal });
    });
    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({ refreshing: true });
        this.asyncRequest = api
          .get('/servico/totalizadores', { headers })
          .then(({ data }) => {
            this.asyncRequest = null;
            this.setState({ data, refreshing: false });
          });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.periodo !== prevProps.periodo) {
      const headers = {};
      headers.dataInicial = this.props.dateRange.dataInicial;
      headers.dataFinal = this.props.dateRange.dataFinal;
      this.setState({ period: this.props.periodo });
      if (headers.dataInicial && headers.dataFinal) {
        this.setState({ refreshing: true });
        api.get('/servico/totalizadores', { headers }).then(({ data }) => {
          this.setState({ data, refreshing: false });
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { period, data, refreshing } = this.state;
    return (
      <>
        <Card
          className="card card__light"
          title="Faturamento de Serviços"
          period={period}
          onChangeDate={this.onChangeDate}>
          <SectionItemCurrency
            title="Vendas"
            subtitle={`Ticket médio - ${formatCurrency(data.vendas.valorMedio)}`}
            icon="venda"
            refreshing={refreshing}
            count={data.vendas.quantidade}
            total={data.vendas.valorTotal}
          />
          <SectionItemCurrency
            title="Cancelamentos"
            icon="cancelamento"
            refreshing={refreshing}
            count={data.cancelamentos.quantidade}
            total={data.cancelamentos.valorTotal}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardServicosTotalizadores);
