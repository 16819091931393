import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';

import api from '../../../../services/api';
import { formatDateApi, formatCurrency } from '../../../../utils/formats';
import { synchronizeData } from '../../../../utils/synchronizeData';

import Card from '../../Card';
import LineChart from '../../../Charts/LineChart';
import Empty from '../../../Empty';

class CardVendasPorHora extends Component {
  constructor() {
    super();

    this.state = {
      noData: true,
      refreshing: false,
      data: { categories: [], series: [] },
      date: formatDateApi(new Date()),
    };
    this.headers = { pickedDate: new Date() };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
    this.hoursOfDay = this.generateHoursOfDay();
  }

  generateHoursOfDay() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(`${i.toString()}h`);
    }
    return hours;
  }

  apiCall = () => {
    this.setState({ refreshing: true });
    api.get('/dashboard/vendas-hora').then(({ data }) => {
      this.asyncRequest = null;
      const { vendasEfetivadas, vendasCanceladas } = data;
      this.setState({
        noData: vendasEfetivadas.length > 0 || vendasCanceladas.length > 0 ? false : true,
        refreshing: false,
        data: {
          categories: this.hoursOfDay.map(hora => hora),
          series: [
            {
              name: 'Efetivadas',
              data: vendasEfetivadas.map(vendaEfetivada => vendaEfetivada),
              color: '#119FE3',
              borderColor: null,
            },
            {
              name: 'Canceladas',
              data: vendasCanceladas.map(vendaCancelada => vendaCancelada),
              color: '#f25454',
              borderColor: null,
            },
          ],
        },
      });
    });
  };

  apiCallUpdate = () => {
    this.setState({ refreshing: true });
    api.get('/dashboard/vendas-hora', { headers: this.headers }).then(({ data }) => {
      this.asyncRequest = null;
      const { vendasEfetivadas, vendasCanceladas } = data;
      this.setState({
        noData: vendasEfetivadas.length > 0 || vendasCanceladas.length > 0 ? false : true,
        refreshing: false,
        data: {
          categories: this.hoursOfDay.map(hora => hora),
          series: [
            {
              name: 'Efetivadas',
              data: vendasEfetivadas.map(vendaEfetivada => vendaEfetivada),
              color: '#119FE3',
              borderColor: null,
            },
            {
              name: 'Canceladas',
              data: vendasCanceladas.map(vendaCancelada => vendaCancelada),
              color: '#f25454',
              borderColor: null,
            },
          ],
        },
      });
    });
  };

  componentDidMount() {
    this.apiCall();
    synchronizeData(this.apiCallUpdate);
  }

  componentDidUpdate() {
    if (this.headers.pickedDate !== this.state.date) {
      this.headers.pickedDate = this.state.date;
      if (this.headers.pickedDate) {
        this.apiCallUpdate();
      }
    }
  }

  onChangeDate = date => {
    this.setState({ ...this.state, date });
  };

  render() {
    const { refreshing, data, noData } = this.state;
    const cardStyle = 'card card__light';

    return (
      <>
        <Card
          title={'Vendas Por Hora'}
          className={cardStyle}
          datePicker
          onChangeDate={this.onChangeDate}
          allowsFilter={false}>
          {!noData ? (
            <LineChart
              refreshing={refreshing}
              data={data}
              options={{
                chart: {
                  type: 'line',
                },
                plotOptions: {
                  line: {
                    dataLabels: {
                      style: {
                        color: '#666',
                        fontWeight: 600,
                        border: 'none',
                      },
                      enabled: true,
                      formatter: function() {
                        return formatCurrency(this.y);
                      },
                    },
                  },
                },
                yAxis: {
                  title: {
                    text: 'Valores',
                  },
                  stackLabels: {
                    style: {
                      color: '#666',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              }}
            />
          ) : (
            <Empty icon="lineChart" cardStyle={cardStyle} />
          )}
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardVendasPorHora);
