import React from 'react';
import { IonItem, IonText, IonCheckbox } from '@ionic/react';
import './styles.css';

export default function Checkbox({ checked, visualizarTodos }) {
  return (
    <>
      <div>
        <IonItem>
          <IonCheckbox
            className="checkbox"
            checked={checked}
            onIonChange={() => visualizarTodos(!checked)}
          />
          <IonText className="labelText">Desmarcar todos</IonText>
        </IonItem>
      </div>
    </>
  );
}
