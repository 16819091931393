import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';

import api, { onRefreshing } from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formats';

import Card from '../../Card';
import LineChart from '../../../Charts/LineChart';
import { synchronizeData } from '../../../../utils/synchronizeData';

class CardComparativoFaturamentoMeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noData: false,
      refreshing: false,
      period: '',
      data: null,
      date: new Date().toISOString(),
    };
    this.headers = { pickedDate: new Date() };
    this.title = 'Comparativo Faturamento x Meta Mensal';
    this.monthNames = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];
    this.subscriptionRefresh = null;
    this.asyncRequest = null;
  }

  apiCall() {
    this.setState({ period: new Date().getFullYear() });
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      this.setState({
        refreshing: true,
        data: { categories: [], series: [] },
      });
      this.asyncRequest = api
        .get('/servico/comparativo-receita-meta')
        .then(({ data }) => {
          this.asyncRequest = null;
          const { receitas, meta } = data;
          this.setState({
            refreshing: false,
            data: {
              noData: receitas.length > 0 || meta.length > 0 ? false : true,
              categories: this.monthNames,
              series: [
                {
                  name: 'Faturamento',
                  type: 'column',
                  data: receitas,
                  color: '#2D2C89',
                  borderColor: null,
                },
                {
                  name: 'Meta',
                  type: 'spline',
                  data: meta,
                  color: '#F9C94E',
                },
              ],
            },
          });
        });
    });
  }

  apiCallUpdate() {
    this.setState({ period: new Date().getFullYear() });
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      this.setState({
        refreshing: true,
        data: { categories: [], series: [] },
      });
      this.asyncRequest = api
        .get('/servico/comparativo-receita-meta', { headers: this.headers })
        .then(({ data }) => {
          this.asyncRequest = null;
          const { receitas, meta } = data;
          this.setState({
            refreshing: false,
            data: {
              noData: receitas.length > 0 || meta.length > 0 ? false : true,
              categories: this.monthNames,
              series: [
                {
                  name: 'Faturamento',
                  type: 'column',
                  data: receitas,
                  color: '#2D2C89',
                  borderColor: null,
                },
                {
                  name: 'Meta',
                  type: 'spline',
                  data: meta,
                  color: '#F9C94E',
                },
              ],
            },
          });
        });
    });
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
  }

  componentDidMount() {
    this.apiCall();
    synchronizeData(this.apiCallUpdate);
  }

  componentDidUpdate() {
    if (this.headers.pickedDate !== this.state.date) {
      this.headers.pickedDate = this.state.date;
      if (this.headers.pickedDate) {
        this.apiCallUpdate();
      }
    }
  }

  onChangeDate = date => {
    this.setState({ ...this.state, date });
  };

  render() {
    const { period, data, date, refreshing } = this.state;
    const { style } = this.props;

    return (
      <>
        <Card
          title={this.title}
          className="card card__light"
          period={period}
          onChangeDate={this.onChangeDate}
          datePicker
          dateYear={date}
          allowsFilter={false}
          style={style}>
          <LineChart
            refreshing={refreshing}
            data={data}
            options={{
              plotOptions: {
                column: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: false,
                  },
                },
                spline: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: false,
                  },
                },
              },
              yAxis: {
                stackLabels: {
                  style: {
                    color: '#666',
                    fontWeight: 600,
                    border: 'none',
                  },
                  enabled: true,
                  formatter: function() {
                    if (this.total > 0) {
                      return formatCurrency(this.total);
                    }
                  },
                },
              },
            }}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardComparativoFaturamentoMeta);
