const { format } = require('date-fns');

export const formatCurrency = function(value) {
  return value
    ? Number(value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      })
    : 'R$ 0,00';
};



export const cnpjMask = function(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '$1.$2');
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');
  return value;
}

export const cpfMask = function(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  return value;
}

export const formatNumber = function(value) {
  return value ? Number(value).toLocaleString('pt-BR') : '0,00';
};
export const formatDateApi = function(value){
  return value ? format(value, 'dd.MM.yyyy') : ''
}

export const formatShortDateApi = function(value){
  return value ? format(value, 'MM/yyyy') : ''
}

export const formatDateHourApi = function(value){
  return value ? format(value, 'H') : ''
}