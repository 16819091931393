import React, { Component } from 'react';
import { IonLabel, IonCol, withIonLifeCycle } from '@ionic/react';

import api from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formats';
import { synchronizeData } from '../../../../utils/synchronizeData';

import Card from '../../Card';
import Loading from '../../../Loading';

import './style.css';

class CardTicketMedio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: null,
    };
  }

  apiCall = () => {
    this.setState({ loading: true })
    api.get('/dashboard/ticket-medio').then(({ data }) => {
      this.setState({ 
        data: data[0], 
        loading: false 
      });
    }).catch(err => {
      this.setState({ loading: false })
    });
  }

  componentDidMount() {
    this.apiCall();
    synchronizeData(this.apiCall);
  }

  render() {
    const { data, loading } = this.state;
    return (
      <>
        <Card
          className="card card__light"
          title="Ticket Médio"
          iconName="icon-ticket"
          dashboardCard
        >
          {loading ? (
            <Loading />
          ) : (
            <div>
              <IonCol className="main-item">
                <div className="container">
                  <IonLabel color="dark" className="label">
                    Este mês:
                  </IonLabel>
                  <IonLabel color="primary" className="value">
                    {data && formatCurrency(data.TICKET_MEDIO_MES)}
                  </IonLabel>
                </div>
              </IonCol>
              <div className="main-section">
                <div className="item">
                  <IonLabel color="dark" className="label">
                    Hoje
                  </IonLabel>
                  <IonLabel color="primary" className="value">
                    {data && formatCurrency(data.TICKET_MEDIO_HOJE)}
                  </IonLabel>
                </div>
                <div className="item">
                  <IonLabel color="dark" className="label">
                    Ontem
                  </IonLabel>
                  <IonLabel color="primary" className="value">
                    {data && formatCurrency(data.TICKET_MEDIO_ONTEM)}
                  </IonLabel>
                </div>
                <div className="item">
                  <IonLabel color="dark" className="label">
                    Semana
                  </IonLabel>
                  <IonLabel color="primary" className="value">
                    {data && formatCurrency(data.TICKET_MEDIO_SEMANA)}
                  </IonLabel>
                </div>
              </div>
            </div>
          )}
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardTicketMedio);
