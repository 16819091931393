import React, { Component } from 'react';
import { withIonLifeCycle, IonLabel } from '@ionic/react';
import { BehaviorSubject } from 'rxjs';
import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi, formatNumber, formatCurrency } from '../../../../utils/formats';
import Card from '../../Card';

const onSelect = new BehaviorSubject();

class CardInadimplenciaAcumulada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      period: '',
      data: {
        recebimentoAtraso: 0,
        taxaInadimplencia: 0,
      },
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      const dataInicial = formatDateApi(new Date());
      const dataFinal = formatDateApi(new Date());
      onSelect.next({ dataInicial, dataFinal });
    });
    this.subscriptionSelect = onSelect.subscribe(data => {
      const { periodo, ...headers } = data;
      this.setState({ period: periodo });
      if (headers.dataInicial !== undefined || headers.dataFinal !== undefined) {
        this.setState({
          refreshing: true,
          data: {
            series: [],
          },
        });
        this.asyncRequest = api
          .get('/financeiro/inadimplencia-acumulada', { headers })
          .then(({ data }) => {
            const { recebimentoAtraso, taxaInadimplencia } = data;
            this.setState({
              refreshing: false,
              data: {
                taxaInadimplencia,
                recebimentoAtraso,
              },
            });
          });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.periodo !== prevProps.periodo) {
      const headers = {};
      headers.dataInicial = this.props.dateRange.dataInicial;
      headers.dataFinal = this.props.dateRange.dataFinal;
      this.setState({ period: this.props.periodo });
      if (headers.dataInicial && headers.dataFinal) {
        this.setState({
          refreshing: true,
          data: {
            series: [],
          },
        });
        this.asyncRequest = api
          .get('/financeiro/inadimplencia-acumulada', { headers })
          .then(({ data }) => {
            const { recebimentoAtraso, taxaInadimplencia } = data;
            this.setState({
              refreshing: false,
              data: {
                taxaInadimplencia,
                recebimentoAtraso,
              },
            });
          });
      }
    }
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
    if (this.subscriptionSelect) this.subscriptionSelect.unsubscribe();
  }

  onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    onSelect.next({ dataInicial, dataFinal, periodo });
  };

  render() {
    const { period, data } = this.state;
    const { style } = this.props;
    return (
      <>
        <Card
          className="card"
          style={style}
          title="Inadimplência"
          period={period}
          dashboardCard
          onChangeDate={this.onChangeDate}>
          <div>
            <div className="main-section">
              <div className="item">
                <IonLabel
                  color="tertiary"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>
                  INADIMPLÊNCIA R$
                </IonLabel>
                <IonLabel
                  color="dark"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>
                  {data && formatCurrency(data.recebimentoAtraso)}
                </IonLabel>
              </div>
              <div className="item">
                <IonLabel
                  color="tertiary"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>
                  INADIMPLÊNCIA %
                </IonLabel>
                <IonLabel
                  color="dark"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>
                  {data !== null ? formatNumber(data.taxaInadimplencia) : 0} %
                </IonLabel>
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardInadimplenciaAcumulada);
