import styled from 'styled-components';
import { IonImg } from '@ionic/react';

export const Form = styled.form`
  padding: 24px;
`;

export const SliderContainer = styled.div`
  padding: 24px;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SliderImg = styled(IonImg)`
  margin-bottom: 20px;
  width: 192px;
`;

export const SliderTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
`;

export const SliderSubtitle = styled.p`
  margin: 0;
  font-size: 15px;
`;
