import React from 'react';

export default function Divider({style}) {
  return (
    <>
      <div
        style={{
          flex: 1,
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 0.5,
          borderStyle: 'solid',
          marginBottom: 15,
          marginTop: 15,
        }}></div>
    </>
  );
}
