import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Plugins } from '@capacitor/core';
import { bindActionCreators } from 'redux';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonToast,
  IonButtons,
  IonBackButton,
  IonIcon,
} from '@ionic/react';
import api from '../../services/api-login';
import * as AuthActions from '../../store/modules/auth/actions';
import Loading from '../../components/Loading';
import { TitleApp } from '../../styles/shared';
import { Form } from './style';
import { settings } from 'ionicons/icons';
import './style.css';
import enviroment from '../../constants/enviroment';
import { cnpjMask, cpfMask } from '../../utils/formats';

const { Storage } = Plugins;

const iconsSlider = {
  configurar: require('../../assets/svg/server.svg'),
  ativar: require('../../assets/svg/building.svg'),
  iniciar: require('../../assets/svg/seo-and-web.svg'),
};

const AddEmpresa = ({ history, activation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState({ isShow: false, message: '', duration: 1000 });
  const [cnpj, setCnpj] = useState('');
  const [inputLogin, setLogin] = useState('');
  const [inputPassword, setPassword] = useState('');
  const [error, setError] = useState('');

  async function handleAtivacao(e) {
    e.preventDefault();
    setIsLoading(true);
    api
      .post('/licenca', { cnpj, login: inputLogin, password: inputPassword })
      .then(({ data }) => {
        if (data.errors) {
          setIsLoading(false);
          setError(data.errors[0]);
        } else {
          Storage.get({ key: 'listaEmpresa' }).then(storage => {
            const listaEmpresa = JSON.parse(storage.value);
            const verificarCnpj = listaEmpresa.find(empresa => empresa.cnpj === cnpj);
            if (verificarCnpj !== undefined) {
              setError('Empresa já cadastrada');
              setIsLoading(false);
            } else {
              listaEmpresa.push({
                cnpj: data.empresa.cnpj,
                token: data.token,
                razaoSocial: data.empresa.razaoSocial,
              });
              Promise.all([
                Storage.set({
                  key: 'listaEmpresa',
                  value: JSON.stringify(listaEmpresa),
                }),
              ]).then(() => {
                setIsLoading(false);
                history.push('/login');
              });
            }
          });
        }
      });
  }

  function handleChangeCnpj(e) {
    const value = e.target.value;
    const cpfCnpj = value?.length === 14 ? cpfMask(value) : cnpjMask(value);
    setCnpj(cpfCnpj);
  }

  return (
    <>
      <IonPage id="activation">
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/login" />
            </IonButtons>
            <TitleApp>
              SIAF <span>Controller</span>
            </TitleApp>
            <IonButtons slot="primary">
              <IonButton onClick={() => history.push('/gerar')}>
                <IonIcon slot="icon-only" icon={settings} size="large" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="fadeIn" fixed>
            <IonRow>
              <IonCol offsetLg="4" sizeLg="4" size="12">
                <div>
                  <div className="slider-intro">
                    <IonImg src={iconsSlider['ativar']} alt="Ativar" />
                    <h1>Adicionar Empresa</h1>
                  </div>

                  <Form onSubmit={e => handleAtivacao(e)}>
                    {isLoading ? (
                      <>
                        <Loading />
                      </>
                    ) : (
                      <>
                        <p id="error">{error !== '' ? error : ''}</p>
                        <div>
                          <p style={{ paddingBottom: 7 }}>CNPJ / CPF</p>
                          <input
                            maxLength="18"
                            className={'activationInput'}
                            value={cnpj?.length === 14 ? cpfMask(cnpj) : cnpjMask(cnpj)}
                            onChange={e => handleChangeCnpj(e)}
                          />
                        </div>
                        <div>
                          <p style={{ paddingBottom: 7 }}>Usuario</p>
                          <input
                            className={isLoading ? 'loginDisabled' : 'loginInput'}
                            disabled={isLoading}
                            required={true}
                            value={inputLogin}
                            onChange={e => setLogin(e.target.value)}
                          />
                        </div>
                        <div>
                          <p style={{ paddingBottom: 7 }}>Senha</p>
                          <input
                            className={isLoading ? 'loginDisabled' : 'loginInput'}
                            disabled={isLoading}
                            required={true}
                            autocomplete="off"
                            type="password"
                            value={inputPassword}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                      </>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}>
                      <IonButton
                        type="submit"
                        color="primary"
                        expand="block"
                        disabled={isLoading}>
                        Adicionar
                      </IonButton>
                    </div>
                  </Form>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <footer className="versao">Versão Siaf Controller - {enviroment.version}</footer>
      </IonPage>
      <IonToast
        isOpen={toast.isShow}
        onDidDismiss={() => setToast({ isShow: false })}
        message={toast.message}
        duration="1000"
      />
    </>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(null, mapDispatchToProps)(AddEmpresa);
