import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';
import api from '../../../../services/api';
import { BehaviorSubject } from 'rxjs';
import Card from '../../Card';
import SectionItemCurrency from '../../../Section/SectionItemCurrency';

const onSelect = new BehaviorSubject('');

class CardEstoqueTotalizadores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      data: {
        estoqueVenda: 0,
        estoqueCusto: 0,
        estoqueCompra: 0,
        estoqueMedio: 0,
      },
      period: 'Hoje',
    };
    this.subscriptionRefresh = null;
    this.asyncRequest = null;
    this.subscriptionSelect = null;
  }

  componentDidMount() {
    this.subscriptionSelect = onSelect.subscribe(data => {
      this.setState({ refreshing: true, actionSelected: data });
      this.asyncRequest = api.get('/estoque/totalizadores').then(({ data }) => {
        this.asyncRequest = null;
        this.setState({ data, refreshing: false });
      });
    });
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
  }

  render() {
    const { data, refreshing } = this.state;

    return (
      <>
        <Card title="Estoque financeiro" allowsFilter={false} dashboardCard>
          <SectionItemCurrency
            title="Total Estoque x Pr.Venda"
            icon="venda"
            refreshing={refreshing}
            total={data.estoqueVenda}
          />
          <SectionItemCurrency
            title="Total Estoque x Pr.Compra"
            icon="custo"
            refreshing={refreshing}
            total={data.estoqueCompra}
          />
          <SectionItemCurrency
            title="Total Estoque x Pr.Custo Calc"
            icon="compra"
            refreshing={refreshing}
            total={data.estoqueCusto}
          />
          <SectionItemCurrency
            title="Total Estoque x Pr.Custo Médio"
            icon="compra"
            refreshing={refreshing}
            total={data.estoqueMedio}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardEstoqueTotalizadores);
