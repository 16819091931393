import React, { useState } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import { formatDateApi } from '../../../utils/formats';
import Card from '../../../components/Cards/Card';
import CardFaturamentoTotalizadores from '../../../components/Cards/Faturamento/Totalizadores';
import CardCrescimentoFaturamentoAnual from '../../../components/Cards/Faturamento/CrescimentoFaturamentoAnual';
import CardMetaFaturamentoAnual from '../../../components/Cards/Faturamento/MetaFaturamentoAnual';
import CardComparativoFaturamentoMeta from '../../../components/Cards/Faturamento/ComparativoFaturamentoMeta';
import CardComparativoVendasVendedor from '../../../components/Cards/Faturamento/ComparativoVendasVendedor';
import CardVendasPorVendedor from '../../../components/Cards/Faturamento/VendasPorVendedor';
import CardVendasPorGrupoProduto from '../../../components/Cards/Faturamento/VendasPorGrupoProduto';
import CardVendasPorTipoPagamento from '../../../components/Cards/Faturamento/VendasPorTipoPagamento';
import CardProdutosMaisVendidos from '../../../components/Cards/Faturamento/ProdutosMaisVendidos';
import CardClientesQueMaisCompraram from '../../../components/Cards/Faturamento/ClientesQueMaisCompraram';
import CardComparativoVendasSemanal from '../../../components/Cards/Faturamento/ComparativoVendasSemanal';

export default function Faturamento() {
  const [period, setPeriod] = useState('Hoje');
  const [dateRange, setDateRange] = useState({
    dataInicial: formatDateApi(new Date()),
    dataFinal: formatDateApi(new Date()),
  });

  const onChangeDate = ({ dataInicial, dataFinal, periodo }) => {
    setDateRange({ dataInicial, dataFinal });
    setPeriod(periodo);
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <Card
                title="Alterar Data Em Lote"
                period={period}
                onChangeDate={onChangeDate}
                style={{ maxHeight: 72 }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <CardFaturamentoTotalizadores dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardCrescimentoFaturamentoAnual />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardMetaFaturamentoAnual />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="12" size="12">
              <CardComparativoFaturamentoMeta />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="12" size="12">
              <CardComparativoVendasVendedor />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="4" size="12">
              <CardVendasPorVendedor dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardVendasPorGrupoProduto dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="4" size="12">
              <CardVendasPorTipoPagamento dateRange={dateRange} periodo={period} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="6" size="12">
              <CardProdutosMaisVendidos dateRange={dateRange} periodo={period} />
            </IonCol>
            <IonCol sizeLg="6" size="12">
              <CardClientesQueMaisCompraram dateRange={dateRange} periodo={period} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CardComparativoVendasSemanal />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
