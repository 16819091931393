import React from 'react';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { formatCurrency } from '../../../utils/formats';
import Loading from '../../Loading';

require('highcharts/modules/full-screen')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default function LineChart({ onClick, chartRef, refreshing, data, options, allowChartUpdate, updateArgs }) {
  const chartOptions = {};

  _.merge(
    chartOptions,
    {
      title: '',
      chart: {
        backgroundColor: null,
        style: {
          fontFamily: "'Unica One', sans-serif",
        },
        height: 300,
        //width: 'auto',
        plotBorderColor: '#0C0C0C',
      },
      responsive: true,
      credits: {
        enabled: false,
      },

      legend: {
        itemStyle: {
          color: '#0C0C0C',
        },
        itemHoverStyle: {
          color: '#eee',
        },
        itemHiddenStyle: {
          color: '#0C0C0C',
        },
        title: {
          style: {
            color: '#000',
          },
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        borderColor: null,
        borderRadius: 0,
        formatter: function () {
          const { x, points } = this;
          return `<div class="chart-tip">
            <span class="title">${x}</span>
            ${points
              .map(
                ({ series, y }) => `
                <div class='content'>
                  <span style="color: ${series.color}; font-size: 20px;">●</span>
                  <b>${series.name}: </b> ${formatCurrency(y)}
                </div>             
              `,
              )
              .join('')}
          </div>`;
        },
      },
      plotOptions: {
        // column: {
        //   stacking: 'normal',
        //   dataLabels: {
        //     enabled: false
        //   }
        // },
        candlestick: {
          lineColor: '#1F4F77',
        },
      },
      series: [],
      xAxis: {
        title: {
          text: '',
          style: {
            color: '#0C0C0C',
          },
        },
        categories: [],
        crosshair: true,
        gridLineColor: '#119FE3',
        labels: {
          style: {
            color: '#666',
          },
        },
        lineColor: '#aaa',
        minorGridLineColor: '#505053',
        tickColor: '#119FE3',
      },
      yAxis: {
        title: {
          text: '',
          style: {
            color: '#0C0C0C',
          },
        },
        // stackLabels: {
        //   style: {
        //     color: '#666',
        //     fontWeight: 600,
        //     border: 'none'
        //   },
        //   enabled: true,
        //   formatter: function () {
        //     return formatCurrency(this.total);
        //   }
        // },
        gridLineColor: '#E0E0E3',
        labels: {
          // format: 'R$ {value}',
          style: {
            color: '#666',
          },
        },
        lineColor: '#119FE3',
        minorGridLineColor: '#505053',
        tickColor: '#119FE3',
      },
    },
    options,
  );

  chartOptions.series = refreshing || data === null ? [] : [...data.series];
  chartOptions.xAxis.categories = refreshing || data === null ? [] : [...data.categories];

  return (
    <>
      {refreshing || data === null ? (
        <Loading isDark={false} />
      ) : (
        <HighchartsReact
          onClick={event => {console.log(event.target)}}
          ref={chartRef}
          highcharts={Highcharts}
          options={chartOptions}
          updateArgs={updateArgs}
          allowChartUpdate={allowChartUpdate}
          style={{ margin: 20, heidth: 150 }}
        />
      )}
    </>
  );
}
