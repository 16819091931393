import React from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import CardEstoqueTotalizadores from '../../../components/Cards/Estoque/Totalizadores';
import CardEstoqueMinimoUltrapassado from '../../../components/Cards/Estoque/EstoqueMinimoUltrapassado';
import CardEstoqueFinanceiro from '../../../components/Cards/Estoque/EstoqueFinanceiro';

export default function Estoque() {
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol sizeLg="6" size="12">
              <IonRow>
                <IonCol sizeLg="12" size="12">
                  <CardEstoqueTotalizadores />
                </IonCol>
                <IonCol sizeLg="12" size="12">
                  <CardEstoqueFinanceiro />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol sizeLg="6" size="12">
              <CardEstoqueMinimoUltrapassado />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
