import React, { Component } from 'react';
import { withIonLifeCycle, IonLabel } from '@ionic/react';
import api from '../../../../services/api';
import Card from '../../Card';
import { formatCurrency } from '../../../../utils/formats';

class CardMetaFaturamentoAnual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      data: null,
    };
    this.subscriptionRefresh = null;
    this.subscriptionSelect = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.setState({ refreshing: true });
    api.get('/servico/meta-receita-anual').then(({ data }) => {
      const { totalVendaAnual, EmpresaMetaAnual, porcentagemMeta } = data;
      this.setState({
        refreshing: false,
        data: {
          porcentagem: porcentagemMeta,
          TOTAL_VENDAS_ANUAL: totalVendaAnual,
          EMPRESA_META_ANUAL: EmpresaMetaAnual,
        },
      });
    });
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <Card
          className="card card__primary"
          title=" Meta de Faturamento Anual"
          dashboardCard>
          <div>
            <div className="main-section">
              <div className="item">
                <IonLabel
                  color="tertiary"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>
                  META
                </IonLabel>
                <IonLabel
                  color="light"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>
                  {data && formatCurrency(data.EMPRESA_META_ANUAL)}
                </IonLabel>
              </div>
              <div className="item">
                <IonLabel
                  color="tertiary"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>
                  FATURAMENTO
                </IonLabel>
                <IonLabel
                  color="light"
                  style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>
                  {data && formatCurrency(data.TOTAL_VENDAS_ANUAL)}
                </IonLabel>
              </div>
            </div>
          </div>
          <div className="item">
            <IonLabel
              color="light"
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 12,
                marginTop: 20,
              }}>
              {data?.porcentagem}% ALCANÇADO
            </IonLabel>
          </div>
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardMetaFaturamentoAnual);
