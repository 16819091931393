import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';

import api, { onRefreshing } from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formats';

import Card from '../../Card';
import Empty from '../../../Empty';
import CustomChart from '../../../Charts/CustomChart';

class CardComparativoVendasSemanal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noData: true,
      refreshing: false,
      period: 'Semana Atual x Semana Anterior',
      data: null,
    };
    this.title = 'Comparativo de Vendas Semanal';
    this.subscriptionRefresh = null;
    this.diasUteis = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB', 'DOM'];

    this.asyncRequest = null;
  }

  montarDataSemana(arraySemanaAtual, arraySemanaPassada, listaSemanas, diaSemanaNumero) {
    const dataAtual = listaSemanas.find(
      valor => (valor.DIASEMANA === diaSemanaNumero) & (valor.SEMANA.trim() === 'atual'),
    );
    const dataPassada = listaSemanas.find(
      valor =>
        (valor.DIASEMANA === diaSemanaNumero) & (valor.SEMANA.trim() === 'passada'),
    );
    arraySemanaAtual.push(dataAtual ? dataAtual.VALORTOTAL : 0);
    arraySemanaPassada.push(dataPassada ? dataPassada.VALORTOTAL : 0);
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      this.setState({
        refreshing: true,
        data: { categories: [], series: [] },
      });

      this.asyncRequest = api.get('/faturamento/comparativo-semanal').then(({ data }) => {
        this.asyncRequest = null;
        if (data.length) {
          const categories = [
            'segunda',
            'terça',
            'quarta',
            'quinta',
            'sexta',
            'sábado',
            'domingo',
          ];
          const dataSemanaAtual = [];
          const dataSemanaPassada = [];
          for (const diaSemana of categories) {
            switch (diaSemana) {
              case 'segunda':
                this.montarDataSemana(dataSemanaAtual, dataSemanaPassada, data, 1);
                break;
              case 'terça':
                this.montarDataSemana(dataSemanaAtual, dataSemanaPassada, data, 2);
                break;
              case 'quarta':
                this.montarDataSemana(dataSemanaAtual, dataSemanaPassada, data, 3);
                break;
              case 'quinta':
                this.montarDataSemana(dataSemanaAtual, dataSemanaPassada, data, 4);
                break;
              case 'sexta':
                this.montarDataSemana(dataSemanaAtual, dataSemanaPassada, data, 5);
                break;
              case 'sábado':
                this.montarDataSemana(dataSemanaAtual, dataSemanaPassada, data, 6);
                break;
              case 'domingo':
                this.montarDataSemana(dataSemanaAtual, dataSemanaPassada, data, 0);
                break;
              default:
                break;
            }
          }
          this.setState({
            noData:
              dataSemanaAtual.length > 0 || dataSemanaPassada.length > 0 ? false : true,
            refreshing: false,
            data: {
              categories: categories,
              series: [
                {
                  name: 'Semana Atual',
                  color: '#119FE3',
                  borderColor: null,
                  data: dataSemanaAtual,
                },
                {
                  name: 'Semana Anterior',
                  data: dataSemanaPassada,
                  color: '#f25454',
                  borderColor: null,
                },
              ],
            },
          });
        }
      });
    });
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
  }

  render() {
    const { period, data, refreshing, noData } = this.state;
    const { style } = this.props;
    const cardStyle = 'card card__light';

    return (
      <>
        <Card
          title={this.title}
          className={cardStyle}
          period={period}
          allowsFilter={false}
          style={style}>
          {!noData ? (
            <CustomChart
              refreshing={refreshing}
              data={data}
              options={{
                chart: {
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    dataLabels: {
                      style: {
                        color: '#666',
                        fontWeight: 600,
                        border: 'none',
                      },
                      enabled: true,
                      formatter: function() {
                        return formatCurrency(this.y);
                      },
                    },
                  },
                },
                yAxis: {
                  stackLabels: {
                    style: {
                      color: '#666',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                  },
                },
                tooltip: {
                  formatter: function() {
                    const { x, y, series } = this;
                    return `
                    <div class="chart-tip">
                      <span class="title">${x}</span>
                      <div class='content'>
                        <span style="color: ${series.color}; font-size: 20px;">●</span>
                        <b>${series.name}: </b> ${formatCurrency(y)}
                      </div>  
                    </div>`;
                  },
                },
              }}
            />
          ) : (
            <Empty icon="lineChart" cardStyle={cardStyle} />
          )}
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardComparativoVendasSemanal);
