import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import { formatNumber, formatCurrency } from '../../../utils/formats';
import Loading from '../../Loading';
import Empty from '../../Empty';

var pieColors = (function() {
  var colors = [],
    base = Highcharts.getOptions().colors[0],
    i;

  for (i = 0; i < 10; i += 1) {
    // Start out with a darkened base color (negative brighten), and end
    // up with a much brighter color
    colors.push(
      Highcharts.Color.parse(base)
        .brighten((i - 3) / 7)
        .get(),
    );
  }
  return colors;
})();

export default function PieChart({ refreshing, numberFormat = 'currency', isDark = true, data, options }) {
  const chartOptions = {};

  _.merge(
    chartOptions,
    {
      title: {
        text: '',
      },
      chart: {
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        plotBorderColor: null,
        height: 260,
        type: 'pie',
        style: {
          fontFamily: "'Unica One', sans-serif",
        },
      },
      responsive: true,
      credits: {
        enabled: false,
      },
      legend: {
        layout: 'horizontal',
        itemStyle: {
          color: '#E0E0E3',
        },
        itemHoverStyle: {
          color: '#FFF',
        },
        itemHiddenStyle: {
          color: '#606063',
        },
        title: {
          style: {
            color: '#C0C0C0',
          },
        },
      },
      tooltip: {
        shared: !1,
        useHTML: !0,
        backgroundColor: 'transparent',
        borderWidth: 0,
        borderRadius: 0,
        formatter: function() {
          const { point } = this;

          return `
          <div class="chart-tip ${point.tipClass}">
            <span class="title">${point.name}</span>                    
            <div class='content'></div>
            <span style="color: ${point.color}; font-size: 20px;">●</span>
            <b>${point.percentage.toFixed(2)} %</b>
            (${numberFormat === 'number' ? formatNumber(point.y) : formatCurrency(point.y)})
          </div>
        `;
        },
        valuePrefix: 'R$ ',
        valueDecimals: 2,
        shadow: !1,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: pieColors,
          dataLabels: {
            enabled: false,
            inside: true,
          },
          showInLegend: true,
          innerSize: '50%',
          borderColor: null,
        },
      },

      series: [],
      drilldown: [],
    },
    options,
  );

  chartOptions.series = refreshing || data === null ? [] : [...data.series];

  //chartOptions.drilldown = refreshing || data === null ? [] : data.drilldown !== null ? [...data.drilldown] : [];

  return (
    <>
      {refreshing || data === null ? (
        <Loading isDark={isDark} />
      ) : (
        <>
          {chartOptions.series.length > 0 ? (
            <>
              <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
            </>
          ) : (
            <Empty icon="pieChart" />
          )}
        </>
      )}
    </>
  );
}
