import React, { Component } from 'react';
import { withIonLifeCycle, IonImg, IonItem, IonLabel, IonList } from '@ionic/react';
import api, { onRefreshing } from '../../../../services/api';
import Card from '../../Card';
import PieChart from '../../../Charts/PieChart';
import Divider from '../../../Divider';
import Loading from '../../../Loading';
import Empty from '../../../Empty';

class CardEstoqueMinimoUltrapassado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      data: null,
    };

    this.subscriptionRefresh = null;

    this.asyncRequest = null;
  }

  componentDidMount() {
    this.subscriptionRefresh = onRefreshing.subscribe(() => {
      this.setState({ refreshing: true });

      this.asyncRequest = api.get('/estoque/minimo-ultrapassado/').then(({ data }) => {
        this.asyncRequest = null;
        const { itens, totalizadores } = data;

        this.setState({
          refreshing: false,
          data: {
            itens: itens.slice(0, 10),
            series: [
              {
                name: 'estoque',
                colorByPoint: true,
                data: [
                  {
                    name: 'MÍNIMO ULTRAPASSADO',
                    y: totalizadores.estoqueMinimoUltrapassado,
                    color: '#DF5353',
                  },
                  {
                    name: 'NORMAL',
                    y: totalizadores.estoqueNormal,
                    color: '#55BF3B',
                  },
                  {
                    name: 'MÁXIMO ULTRAPASSADO',
                    y: totalizadores.estoqueMaximoUltrapassado,
                    color: '#DDDF0D',
                  },
                ],
              },
            ],
          },
        });
      });
    });
  }

  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
  }

  render() {
    const { data, refreshing } = this.state;
    return (
      <>
        <Card
          title="Estoque mínimo ultrapassado"
          className="card card__primary"
          dashboardCard
          allowsFilter={false}>
          {refreshing || data === null ? (
            <Loading isDark={true} />
          ) : (
            <>
              <PieChart
                data={data}
                numberFormat="number"
                options={{
                  plotOptions: {
                    pie: {
                      startAngle: -90,
                      endAngle: 90,
                      center: ['50%', '75%'],
                      size: '110%',
                      allowPointSelect: true,
                      cursor: 'pointer',
                      dataLabels: {
                        style: {
                          color: '#fff',
                          fontWeight: 600,
                        },
                        enabled: true,
                        format: '{y}',
                      },
                    },
                  },
                }}
              />
              <Divider />
              {data.itens.length > 0 ? (
                <IonList>
                  {data.itens.map(
                    ({
                      produto,
                      produtoId,
                      estoque,
                      estoqueMinimo,
                      estoqueMaximo,
                      qtdSugeridaMinima,
                      qtdSugeridaMaxima,
                    }) => (
                      <IonItem key={produtoId} style={{ paddingBottom: 8 }}>
                        <IonImg
                          style={{ width: 24 }}
                          src={require('../../../../assets/svg/icon-produto-alternativo.svg')}
                        />
                        <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                          <IonLabel
                            color="light"
                            style={{
                              fontWeight: 600,
                              whiteSpace: 'normal',
                              fontSize: 12,
                            }}>
                            {`${produtoId} - ${produto}`}
                          </IonLabel>
                          <div style={{ marginBottom: 8 }}>
                            <IonLabel color="light" style={{ fontSize: 11 }}>
                              Estoque Atual: {` ${estoque ? estoque.toFixed(2) : 0}`}
                            </IonLabel>
                            <IonLabel color="light" style={{ fontSize: 11 }}>
                              Estoque Mínino:
                              {` ${estoqueMinimo ? estoqueMinimo.toFixed(2) : 0}`}
                            </IonLabel>
                            <IonLabel color="light" style={{ fontSize: 11 }}>
                              Estoque Máximo:
                              {` ${estoqueMaximo ? estoqueMaximo.toFixed(2) : 0}`}
                            </IonLabel>
                            <IonLabel color="light" style={{ fontSize: 11 }}>
                              Quantidade Sugerida (Mínimo):
                              {` ${qtdSugeridaMinima ? qtdSugeridaMinima.toFixed(2) : 0}`}
                            </IonLabel>
                            <IonLabel color="light" style={{ fontSize: 11 }}>
                              Quantidade Sugerida (Maximo):
                              {` ${qtdSugeridaMaxima ? qtdSugeridaMaxima.toFixed(2) : 0}`}
                            </IonLabel>
                          </div>
                        </div>
                      </IonItem>
                    ),
                  )}
                </IonList>
              ) : (
                <Empty icon="box" style={{ paddingBottom: 12 }} />
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardEstoqueMinimoUltrapassado);
