import React, { Component } from 'react';
import { withIonLifeCycle, IonLabel } from '@ionic/react';
import sub from 'date-fns/sub';
import api, { onRefreshing } from '../../../../services/api';
import { formatDateApi } from '../../../../utils/formats';
import { formatCurrency } from '../../../../utils/formats';
import Card from '../../Card';
import CustomChart from '../../../Charts/CustomChart';
import Divider from '../../../Divider';

class CardComparativoFinanceiro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      period: '',
      dataRecebimento: null,
      dataPagamento: null,
    };

    this.subscriptionRefresh = null;
    this.asyncRequest = null;
  }

  componentDidMount() {
    this.setState({ period: '7 Dias' });

    this.subscriptionRefresh = onRefreshing.subscribe(data => {
      this.setState({ refreshing: true });
      const dataBase = new Date();
      const dataInicial = formatDateApi(sub(dataBase, { days: 7 }));
      const dataFinal = formatDateApi(dataBase);
      const headers = {
        dataInicial,
        dataFinal,
      };
      this.asyncRequest = api
        .get('/financeiro/comparativo-financeiro', { headers })
        .then(({ data }) => {
          this.asyncRequest = null;
          const { pagamentos, recebimentos } = data;
          this.setState({
            refreshing: false,
            dataRecebimento: {
              categories: recebimentos.map(item => item.dataRefencia),
              series: [
                {
                  name: 'Recebimento Realizado',
                  data: recebimentos.map(item => item?.recebimentoRealizado),
                  color: '#10dc60',
                  stack: 'recebimento',
                },
                {
                  name: 'Recebimento Previsto',
                  data: recebimentos.map(item => item?.recebimentoPrevisto),
                  color: '#119FE3',
                  stack: 'recebimento',
                },
              ],
            },
            dataPagamento: {
              categories: pagamentos.map(item => item.dataRefencia),
              series: [
                {
                  name: 'Pagamento Previsto',
                  data: pagamentos.map(item => item?.pagamentoPrevisto),
                  color: '#ffce00',
                  stack: 'pagamento',
                },
                {
                  name: 'Pagamento Realizado',
                  data: pagamentos.map(item => item?.pagamentoRealizado),
                  color: '#f04141',
                  stack: 'pagamento',
                },
              ],
            },
          });
        });
    });
  }
  componentWillUnmount() {
    if (this.subscriptionRefresh) this.subscriptionRefresh.unsubscribe();
  }

  render() {
    const { period, dataRecebimento, dataPagamento, refreshing } = this.state;
    const { style } = this.props;
    return (
      <>
        <Card
          title="Comparativo Financeiro"
          className="card card__light"
          style={style}
          period={period}
          allowsFilter={false}>
          <div className="item">
            <IonLabel
              color="tertiary"
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 12,
                margin: 12,
              }}>
              RECEBIMENTOS
            </IonLabel>
          </div>
          <CustomChart
            refreshing={refreshing}
            data={dataRecebimento}
            options={{
              chart: {
                type: 'column',
              },
              plotOptions: {
                column: {
                  dataLabels: {
                    style: {
                      color: '#666',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              },
              yAxis: {
                stackLabels: {
                  style: {
                    color: '#666',
                    fontWeight: 600,
                    border: 'none',
                  },
                  enabled: true,
                },
              },
              tooltip: {
                formatter: function() {
                  const { x, y, series } = this;
                  return `
                  <div class="chart-tip">
                    <span class="title">${x}</span>
                    <div class='content'>
                      <span style="color: ${series.color}; font-size: 20px;">●</span>
                      <b>${series.name}: </b> ${formatCurrency(y)}
                    </div>  
                  </div>`;
                },
              },
            }}
          />
          <Divider />
          <div className="item">
            <IonLabel
              color="tertiary"
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 12,
                margin: 12,
              }}>
              PAGAMENTOS
            </IonLabel>
          </div>
          <CustomChart
            refreshing={refreshing}
            data={dataPagamento}
            options={{
              chart: {
                type: 'column',
              },
              plotOptions: {
                column: {
                  dataLabels: {
                    style: {
                      color: '#666',
                      fontWeight: 600,
                      border: 'none',
                    },
                    enabled: true,
                    formatter: function() {
                      return formatCurrency(this.y);
                    },
                  },
                },
              },
              yAxis: {
                stackLabels: {
                  style: {
                    color: '#666',
                    fontWeight: 600,
                    border: 'none',
                  },
                  enabled: true,
                },
              },
              tooltip: {
                formatter: function() {
                  const { x, y, series } = this;
                  return `
                  <div class="chart-tip">
                    <span class="title">${x}</span>
                    <div class='content'>
                      <span style="color: ${series.color}; font-size: 20px;">●</span>
                      <b>${series.name}: </b> ${formatCurrency(y)}
                    </div>  
                  </div>`;
                },
              },
            }}
          />
        </Card>
      </>
    );
  }
}

export default withIonLifeCycle(CardComparativoFinanceiro);
