import styled from 'styled-components';

const ContainerLoading = styled.div`
  flex: 1;
  flex-direction: column;
  margin-bottom: 32px;
  text-align: center;
`;

export default ContainerLoading;
